import React from "react"
import returnIcon from "../assets/images/icon-left.svg"
import TopBar from "../components/dashboard/topbar.js";
import CategoryIcon from "../components/dashboard/categoryIcon.js";
import { Link } from "react-router-dom";
import { firestore } from "../middlewares/firebase";
import { TeacherDataContext } from "../providers/TeacherDataProvider";
import Loader from "../components/game/loader";
import { parseChallengeType } from "../utils/funcs"

class StudentAnswers extends React.Component {
  static contextType = TeacherDataContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      student: null,
      submissions: null,
      selectedChallenge: null,
    }
  }

  async componentDidMount() {
    let studentId = this.props.match.params.studentId;
    let student = (await firestore.collection('students')
                    .doc(studentId).get()).data();

    let submissions = (await firestore.collection(`students/${studentId}/answers`)
                        .get()).docs.map(doc => doc.data());

    submissions = submissions.sort((a, b) => (a.level - b.level) || ((a.problem > b.problem) ? 1 : ((a.problem < b.problem) ? -1 : 0)) || ((a.order > b.order) ? 1 : -1))

    this.setState({
      loading: false,
      student,
      submissions
    });
  }

  renderAnswer(answer) {
    if(answer.type === "file") {
      return (<a
        className="o-dashboard__text-link"
        href={answer.value} target="_blank" rel="noreferrer">
        descarregar ficheiro
      </a>)
    } else {
      return (<p>{answer.value}</p>)
    }
  }

  setSelectedChallenge(index) {
    this.setState({selectedChallenge: index})
  }
  render() {

    if(this.state.loading){
      return <Loader />
    }

    return(
      <div className="o-dashboard">
        <TopBar
          teacherName={this.context.teacher.displayName}
          activeClassName={this.context.activeClass.name}
        />

        {/* STUDENTS VIEW*/}
        <section className="o-dashboard__section">
          <div className="o-dashboard__container">
            <div className="u-mb-6x" data-flout="row-- align-start--">
              <Link
                className="o-dashboard__topbar__link"
                to="/teacher-dashboard"
              >
                <button className="c-button">
                  <img src={returnIcon} alt="professor" height="36px" className="u-pv"/>
                  <span className="u-ml">voltar à turma</span>
                </button>
              </Link>
            </div>
            <div  data-flout="row-- align-center--" data-flitem="grow--">
              <div className="o-dashboard__bam">
                <p className='o-dashboard__bam__text'>{this.context.activeClass.name}</p>
              </div>
              <p className='u-ml-2x u-tw-600'>{this.state.student.name}</p>
              <p className='u-ml u-tw-600'>Nº{this.state.student.studentNo}</p>
            </div>
            <div className="o-grid" data-flout="row--">
              <div className="o-grid__col u-12/12">
                <div className="o-table-wrapper">
                  <table className="o-table">
                    <thead className="o-table__head">
                      <tr className="o-table__headings">
                        <th className="o-table__field u-text-l">DESAFIO</th>
                        <th className="o-table__field u-text-r">RESPOSTAS</th>
                      </tr>
                    </thead>
                    <tbody className="o-table__body">
                      {this.state.submissions.map((submission, key) =>

                        <React.Fragment key={key}>
                          <tr className={`o-table__row o-table__row--collapsable-head ${this.state.selectedChallenge === key && "uncollapsed"}`}>
                            <td className="o-table__cell u-text-l">
                              <div data-flout="row-- align-center--">
                                <CategoryIcon category={submission.type} />
                                <div className="u-ml">
                                  <p className="u-tw-600">{parseChallengeType(submission.type)} {submission.title ? '- "'+submission.title+'"' : ""}</p>
                                  <p>Capítulo {submission.level} - Página {submission.problem} </p>
                                </div>
                              </div>
                            </td>
                            <td className="o-table__cell o-table__cell--grow-xs u-text-r">
                              <label
                              className={`${this.state.selectedChallenge !== key ? "o-table__trigger" : "o-table__trigger o-table__trigger--open"}`}
                              onClick={
                                this.state.selectedChallenge !== key ?
                                () => this.setSelectedChallenge(key) :
                                () => this.setSelectedChallenge(null)
                              }>
                              {this.state.selectedChallenge !== key ?
                                "ver respostas" : "esconder respostas"
                              }
                              </label>
                            </td>
                          </tr>
                          <tr className={`o-table__row o-table__row--collapsable ${this.state.selectedChallenge === key && "uncollapsed"}`}>
                            <td className="o-table__cell o-table__cell--collapsable u-text-l" colSpan={2}>
                              <div className="o-table__collapse-content">
                                {/*START HERE DO THE LOOP TROUGH THE QUESTIONS*/}
                                {submission.answers.sort((a, b) => parseInt(a.order) - parseInt(b.order)).map((answer, key) =>
                                  <React.Fragment key={key}>
                                    <div className="o-table__qa-block">
                                      <p className="o-table__qa-label">pergunta:</p>
                                      <p className="u-mb-2x">{answer.label}</p>
                                      <p className="o-table__qa-label">resposta:</p>
                                      {this.renderAnswer(answer)}
                                    </div>
                                    <hr className="o-table__qa-divider"/>
                                  </React.Fragment>
                                )}
                                {/*END HERE DO THE LOOP TROUGH THE QUESTIONS*/}
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
        </section>
      </div>
    )
  }
}


export default StudentAnswers
