import React, {Component, createContext} from "react"

export const PageContext = createContext({activeLevel: 0, activeProblem: 1});
class PageProvider extends Component {

  constructor(props) {
    super(props);
    this.setPage = this.setPage.bind(this);
    this.state = {
        activeLevel: 0,
        activeProblem: 1,
        setPage: this.setPage
      }
  }

  setPage(level,problem) {
    this.setState({
      activeLevel: level,
      activeProblem: problem
    });
  }

 
  render() {
    return (
      <PageContext.Provider value={this.state} >
        {this.props.children}
      </PageContext.Provider>
    );
  }
}

export default PageProvider