import * as React from "react"
import { Link } from "react-router-dom"
import { PageContext } from '../../providers/PageProvider.js'
import { StudentDataContext } from "../../providers/StudentDataProvider.js"
import { LEVEL_NAMES } from "../../utils/copy"

class Tabs extends React.Component {
  static contextType = StudentDataContext;
  render() {
    const levels = this.context.student.gameState.levels
    return(
      <PageContext.Consumer>
        {({activeLevel, activeProblem, setPage}) => (
          
          <div className="o-tabs">
            {levels.map((level, key) =>
              <div 
                key={key}
                className={`o-tabs__chapter o-tabs__chapter--${level.order} ${activeLevel ===  level.order && "o-tabs__chapter--active"} ${!level.unlocked && "o-tabs__chapter--locked"}`} 
              >
                <Link to="/nivel"
                  className={`o-tabs__title o-tabs__title--${level.order}`}
                  onClick={(e) => setPage(level.order,1)}
                >
                  {LEVEL_NAMES[level.order]}
                </Link>

                  <div className={`o-tabs__navigation o-tabs__navigation--${level.order}`}>
                  {activeLevel ===  level.order &&
                    level.problems.map((problem, key) =>
                      <Link to="/nivel"
                        key={key} 
                        className={`o-tabs__page o-tabs__page--${level.order} ${activeProblem.toString() ===  problem.name  && "o-tabs__page--active"} ${problem.completed && "o-tabs__page--completed"}`}
                        onClick={(e) => setPage(level.order,problem.name)}
                      >
                        {problem.name}
                      </Link> 
                    )
                  }
                  </div> 
                <div className={`o-tabs__background o-tabs__background--${level.order}`} />
              </div>

            )}
          </div>
          
        )}
      </PageContext.Consumer>
    )
  }
}

export default Tabs