import React, { useContext } from 'react'
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

import LoginView from '../views/login'
import LoginConfirmation from '../views/loginConfirmation'
import TeacherLogin from '../views/teacherLogin'

import StudentRouter from "./StudentRouter"
import TeacherRouter from "./TeacherRouter"

import StudentDataProvider from '../providers/StudentDataProvider'
import TeacherDataProvider from '../providers/TeacherDataProvider'
import { AuthContext } from '../providers/AuthProvider'

import Loader from '../components/game/loader.js'

import { USERTYPES } from '../utils/constants.js'

const ApplicationRouter = () => {
  const { loading, auth, userType } = useContext(AuthContext);

  if (loading) {
    return <Loader />
  }

  switch (userType) {
    case USERTYPES.student:
      return (
        <StudentDataProvider studentId={auth.uid}>
          <StudentRouter />
        </StudentDataProvider>
      );

    case USERTYPES.teacher:
      return (
        <TeacherDataProvider teacherId={auth.uid}>
          <TeacherRouter />
        </TeacherDataProvider>
      );

    default:
      return (
        <BrowserRouter>
          <Switch>
            <Route exact path='/'>
              <Redirect to='/login-professor' />
            </Route>
            <Route exact path='/turmas/:slug'
              component={LoginView} >
            </Route>
            <Route path='/login-professor'>
              <TeacherLogin />
            </Route>
            <Route path='/confirmar-login'>
              <LoginConfirmation />
            </Route>
            <Route>
              <Redirect to='/login-professor' />
            </Route>
          </Switch>
        </BrowserRouter>
      )
  }
}

export default ApplicationRouter;