import * as React from "react"
import { Link } from "react-router-dom"
import { StudentDataContext } from "../../providers/StudentDataProvider.js"
import { parseGameVersion } from "../../utils/funcs"

class ProfileTab extends React.Component {
  static contextType = StudentDataContext;
  constructor(props) {
    super(props);
    this.state = {
      avatarHeadUrl: ""
    }
  }
  componentDidMount() {
    const game = parseGameVersion(this.context.activeClass.version)
    import(`../../assets/images/${game}/avatars/${this.context.student.gameState.avatar}/avatar-${this.context.student.avatar}--id-card.svg`).then(image => {this.setState({avatarHeadUrl: image.default})})
  }
  render() {
    return(
      <Link to="/perfil">
        <div className="c-id-card">
          <div className={`c-id-card__photo ${this.props.justCompleted && "c-id-card__photo--animated"}`}>
            <img src={this.state.avatarHeadUrl} alt="heroi"/>
          </div>
          <p className="c-id-card__name">{this.context.student.name}</p>
        </div>
      </Link>
    )
  }
}

export default ProfileTab