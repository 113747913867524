import React, {Component, createContext} from "react";
import { auth } from "../middlewares/firebase";
import { STUDENT_EMAIL_DOMAIN, USERTYPES } from "../utils/constants";

export const AuthContext = createContext({loading: true, auth: null, userType: null});
class AuthProvider extends Component {
  state = {
    loading: true,
    auth: null,
    userType: null,
  };

  componentDidMount = () => {
    // AUTH LISTENER
    // Listenining here for auth changes and acting accordingly
    auth.onAuthStateChanged( userAuth => {

      this.setState({
        loading: false,
        auth: userAuth,
        userType: this.setUserType(userAuth)
      });
    })
  }

  setUserType = (authObject) => {
    if (!authObject)
      return null;

    if ( authObject.email.endsWith(STUDENT_EMAIL_DOMAIN) ) {
      return USERTYPES.student;
    } else {
      return USERTYPES.teacher;
    }
  }

  render() {
    return (
      <AuthContext.Provider value={this.state}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthProvider;