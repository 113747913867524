import * as React from "react"

function themeClass(modifiers) {
  return `c-button ${modifiers ? modifiers : ""}`
}

class Button extends React.Component {
  render() {
    return (
      <button 
        className={themeClass(this.props.modifiers)} 
        disabled={this.props.disabled} 
        name={this.props.name}
        onClick={this.props.onClick}
      >
        {this.props.name}
      </button>
    )
  }
}

export default Button
