import React from 'react';

class AvatarFull extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      avatarThemes: {
        1 : "",
        2 : "",
        3 : "",
        4 : "",
        5 : "",
        6 : "",
      }
    }
  }
  componentDidMount(){
    import(`../../assets/images/${this.props.game}/avatars/avatar-1--selection.svg`).then(image => {this.setState({avatarThemes:  {...this.state.avatarThemes, 1: image.default}}) })
    import(`../../assets/images/${this.props.game}/avatars/avatar-2--selection.svg`).then(image => {this.setState({avatarThemes:  {...this.state.avatarThemes, 2: image.default}}) })
    import(`../../assets/images/${this.props.game}/avatars/avatar-3--selection.svg`).then(image => {this.setState({avatarThemes:  {...this.state.avatarThemes, 3: image.default}}) })
    import(`../../assets/images/${this.props.game}/avatars/avatar-4--selection.svg`).then(image => {this.setState({avatarThemes:  {...this.state.avatarThemes, 4: image.default}}) })
    import(`../../assets/images/${this.props.game}/avatars/avatar-5--selection.svg`).then(image => {this.setState({avatarThemes:  {...this.state.avatarThemes, 5: image.default}}) })
    import(`../../assets/images/${this.props.game}/avatars/avatar-6--selection.svg`).then(image => {this.setState({avatarThemes:  {...this.state.avatarThemes, 6: image.default}}) })
  }
  render() {
    return (
      <img
        className={`c-avatar-full ${this.props.modifiers}`}
        src={this.state.avatarThemes[this.props.theme]}
        alt={`Avatar ${this.props.theme}`}
      />
    )
  }
}

export default AvatarFull
