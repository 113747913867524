import React, {Component, createContext} from "react";
import { firestore, auth, getAvailableChallenges } from "../middlewares/firebase";

export const StudentDataContext = createContext({student: null});
class StudentDataProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student: null,
      activeClass: null,
      claims: null,
      loading: true
    }
  }

  dataListener = null;

  async componentDidMount() {
    // On first mount, we need to setup the first fetch and 
    // refresh actions for getting student data
    let claims = (await auth.currentUser.getIdTokenResult()).claims;
    let activeClass = (await firestore.collection("classes")
                              .doc(claims.classId)
                              .get()).data();
    
    let challenges = await getAvailableChallenges(activeClass.version);                          

    this.dataListener = firestore.collection("students").doc(auth.currentUser.uid)
      .onSnapshot((doc) => {
        this.setState({
          student: doc.data(),
          activeClass,
          challenges,
          claims,
          loading: false
        });
      })
  }

  componentWillUnmount(){
    if(this.dataListener) {
      this.dataListener();
    }
  }

  render() {
    return (
      <StudentDataContext.Provider value={this.state}>
        {this.props.children}
      </StudentDataContext.Provider>
    );
  }
}

export default StudentDataProvider;