import React from "react"
import TabNavigation from "../components/level/tabNavigation.js"
import Profile from "../components/profile/profile.js"
import { PageContext } from "../providers/PageProvider.js"
import { StudentDataContext } from "../providers/StudentDataProvider.js"
import { parseGameVersion } from "../utils/funcs"

class ProfileView extends React.Component {
  static contextType = PageContext;
  componentDidMount() {
    this.context.setPage(null,null);
  }
  render() {
    return(
      <StudentDataContext.Consumer>
        {({ activeClass, student }) => (
          <div className="o-app__bg-wood">
            <div className="o-app__frame o-app__frame--lg">
              <section className="o-view">
                <TabNavigation />
                <Profile
                  levels = {student.gameState.levels.filter(level => level.order !== 0).filter(level => level.unlocked)}
                  student = {student}
                  scoreboard = {student.gameState.scoreboard}
                  game = {parseGameVersion(activeClass.version)}
                  gameStatus = {activeClass.status}
                />
              </section>
            </div>
          </div>
        )}
      </StudentDataContext.Consumer>
    )
  }
}


export default ProfileView
