import * as React from "react"

class BdPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      background: "",
      avatar: "",
      ballons: ""
    }
  }
  componentDidMount() {
    if (this.props.completed){
      if (this.props.panel < this.props.actionPanel){
        import(`../../assets/images/${this.props.game}/chapter-${this.props.level}/page-${this.props.problem}/background/panel-${this.props.panel}.svg`).then(image => {this.setState({background: image.default})})
        import(`../../assets/images/${this.props.game}/chapter-${this.props.level}/page-${this.props.problem}/avatar/avatar-${this.props.avatarTheme}/panel-${this.props.panel}.svg`).then(image => {this.setState({avatar: image.default})})
        import(`../../assets/images/${this.props.game}/chapter-${this.props.level}/page-${this.props.problem}/ballons/panel-${this.props.panel}.svg`).then(image => {this.setState({ballons: image.default})})
      } else {
        import(`../../assets/images/${this.props.game}/chapter-${this.props.level}/page-${this.props.problem}/background/done/panel-${this.props.panel}.svg`).then(image => {this.setState({background: image.default})})
        if (this.props.avatar === "evo2-plus" && this.props.panel === 4) {
          import(`../../assets/images/${this.props.game}/chapter-${this.props.level}/page-${this.props.problem}/avatar/avatar-${this.props.avatarTheme}/done/panel-${this.props.panel}-plus.svg`).then(image => {this.setState({avatar: image.default})})
        } else {
          import(`../../assets/images/${this.props.game}/chapter-${this.props.level}/page-${this.props.problem}/avatar/avatar-${this.props.avatarTheme}/done/panel-${this.props.panel}.svg`).then(image => {this.setState({avatar: image.default})})
        }
        import(`../../assets/images/${this.props.game}/chapter-${this.props.level}/page-${this.props.problem}/ballons/done/panel-${this.props.panel}.svg`).then(image => {this.setState({ballons: image.default})})
      }
    } else {
        import(`../../assets/images/${this.props.game}/chapter-${this.props.level}/page-${this.props.problem}/background/panel-${this.props.panel}.svg`).then(image => {this.setState({background: image.default})})
        import(`../../assets/images/${this.props.game}/chapter-${this.props.level}/page-${this.props.problem}/avatar/avatar-${this.props.avatarTheme}/panel-${this.props.panel}.svg`).then(image => {this.setState({avatar: image.default})})
        import(`../../assets/images/${this.props.game}/chapter-${this.props.level}/page-${this.props.problem}/ballons/panel-${this.props.panel}.svg`).then(image => {this.setState({ballons: image.default})})
    }
  }
  render() {
    return(
      <React.Fragment>
        <img src={this.state.background} alt="fundo" className="o-bd__panel__background"/>
        <img src={this.state.avatar} alt="avatar" className="o-bd__panel__overlay"/>
        <img src={this.state.ballons} alt="text" className="o-bd__panel__overlay"/>
      </React.Fragment>
    )
  }
}

export default BdPanel
