import React from "react"
import gamemastersImg from "../assets/images/gamemasters.png"
import TopBar from "../components/dashboard/topbar.js"
import { TeacherDataContext } from "../providers/TeacherDataProvider"

class TeacherDashboard extends React.Component {
  static contextType = TeacherDataContext;

  constructor(props) {
    super(props);
    this.state = {
      sortBy: "name"
    }
  }
  updateSort(variable){
    this.setState({sortBy: variable});
  }
  sortedTeacherClasses(classes){
    let teacherClasses = classes
    if ( this.state.sortBy === 'school') {
      teacherClasses = classes.sort((a,b) => {
        if (!a.data().school) { return +1 }
        if (!b.data().school) { return -1 }
        else return a.data().school.localeCompare(b.data().school)
      })
    } else if (this.state.sortBy === 'location') {
      teacherClasses = classes.sort((a,b) => {
        if (!a.data().location) { return +1 }
        if (!b.data().location) { return -1 }
        else return a.data().location.localeCompare(b.data().location)
      })
    } else if (this.state.sortBy === 'version') {
      teacherClasses = classes.sort((a,b) => {
        if (!a.data().version) { return +1 }
        if (!b.data().version) { return -1 }
        else return a.data().version.localeCompare(b.data().version)
      })
    } else {
      teacherClasses = classes.sort((a,b) => {
        if (!a.data().name) { return +1 }
        if (!b.data().name) { return -1 }
        else return a.data().name.localeCompare(b.data().name)
      })
    }
    return teacherClasses;
  }
  render() {
    const sortOptions = [
      { name: "ESCOLA", code: 'school', restricted: true },
      { name: "LOCAL", code: 'location', restricted: true },
      { name: "TURMA", code: 'name', restricted: false },
      { name: "VERSÃO DO JOGO", code: 'version', restricted: false },
    ]
    return(
      <div className="o-dashboard">
        <TopBar
          teacherName={this.context.teacher.displayName}
        />
        <section className="o-dashboard__section">
          <div className="o-dashboard__container">
            <div className="o-grid" data-flout="col-- align-center--">
              <div className="o-grid__col u-12/12" data-flout="col-- align-center--">
                <h1 className="o-dashboard__title">
                  Bem Vind@ Gamemaster! Escolha a turma com que vai jogar:
                </h1>
                <div className="o-table-wrapper">
                  <table className="o-table">
                    <thead className="o-table__filter">
                      <tr className="o-table__headings u-hidden-@sm">
                        <th className="o-table__field">ordenar por:</th>
                      </tr>
                      <tr className="o-table__headings">
                        {sortOptions.map((option, key) =>
                          (!option.restricted || this.context.admin) &&
                            <th className="o-table__field o-table__item--bordered u-text-l" key={key}>
                              <div data-flout="justify-between-- align-center-- nowrap--">
                                <span className="u-ml u-hidden u-display--block-@sm">{option.name}</span>
                                <button
                                  key={key}
                                  className={`c-button c-button--filter ${this.state.sortBy === option.code && "sort-by"}`}
                                  onClick={() => this.updateSort(option.code)}
                                >
                                  <span className="u-hidden-@sm">{option.name}</span>
                                  <span className="u-hidden u-display--block-@sm">&#8744;</span>
                                </button>
                              </div>
                            </th>
                        )}
                        <th className="o-table__field"/>
                      </tr>
                    </thead>
                    <tbody className="o-table__body">
                      {this.sortedTeacherClasses(this.context.teacherClasses).map((teacherClass, key) =>
                        <tr className="o-table__row" key={key}>
                          { this.context.admin &&
                            <React.Fragment>
                              <td className="o-table__cell o-table__item--bordered u-text-l">
                                {teacherClass.data().school}
                              </td>
                              <td className="o-table__cell o-table__item--bordered u-text-l">
                                <p>{teacherClass.data().location}</p>
                              </td>
                            </React.Fragment>
                          }
                          <td className="o-table__cell o-table__item--bordered u-tw-600">
                            {teacherClass.data().name}
                          </td>
                          <td className="o-table__cell o-table__item--bordered u-text-l">
                            {teacherClass.data().version}
                          </td>
                          <td className="o-table__cell o-table__cell--grow-xs u-text-r">
                            <button key={key}
                              className="c-button c-button"
                              onClick={() => this.context.classLogin(teacherClass.id)}
                            >
                              Jogar
                            </button>
                          </td>
                        </tr>
                      )}
                      
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="o-grid__col u-12/12 u-6/12@sm u-mt-6x">
                <img width="100%" src={gamemastersImg} alt="Gamemasters"/>
              </div>
           </div>
          </div>
        </section>
      </div>
    )
  }
}


export default TeacherDashboard
