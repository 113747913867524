export const LEVEL_NAMES = {
  0: 'Intro',
  1: 'Capítulo I',
  2: 'Capítulo II',
  3: 'Capítulo III',
};

export const PROBLEM_DESCRIPTIONS = {
  'desenvolvimento-sustentavel': {
    0: [
      'Intro',
      'Olá! Quando clicas na imagem, como acabaste de fazer, recebes toda a informação que precisas para conseguir alterar a tua história. A tua 1.ª missão é desvendar a razão desta grande aventura! Espreita o desafio abaixo e descobre o que te espera.'
    ],
    1: [
      'A sustentabilidade do planeta está em jogo! Há 17 coisas que, até 2030, podem ajudar a salvar o planeta, mas poucas pessoas as conhecem. Se mais gente as conhecer, tudo pode mudar. Vamos dar a volta a isto?',
      'O desperdício continua a espalhar-se. As escolhas que fazemos todos os dias podem ter graves consequências para o planeta. Há 5 R\'s - REPENSAR, RECUSAR, REDUZIR, REUTILIZAR e RECICLAR que podem pôr isto tudo a circular. É preciso entrar em ação!',
      'A forma como nos deslocamos e vivemos pode provocar graves problemas socioambientais. A poluição, a erosão dos solos,a  destruição da biodiversidade e de recursos como água, energia e ar estão a aumentar. Pomos a comunidade a mexer?'
    ],
    2: [
      'Aquilo que consumimos diariamente pode contribuir para a destruição do planeta. O aquecimento global, as secas, as cheias e o degelo atingiram níveis muito preocupantes. Isto tem que arrefecer!',
      'Ainda existem muitas  desigualdades no mundo. Na distribuição de rendimento, no acesso a alimentos, educação, saúde e água potável, ainda são muitas as diferenças entre e dentro dos países. Sem resolver isso não há desenvolvimento sustentável!'
    ],
    3: [
      'Uau, lado a lado numa última aventura! Agora não serei eu a sugerir-te o que fazer. Vais tu decidir a melhor forma que tens para ajudar a resolver este problema, que é de todos nós! Das 4 opções abaixo, só tens de escolher uma! Terminas em grande com esta missão?'
    ]
  },
  'interculturalidade': {
    0: [
      'Intro',
      'Olá! Quando clicas na imagem, como acabaste de fazer, recebes toda a informação que precisas para conseguir alterar a tua história. A tua 1.ª missão é desvendar a razão desta grande aventura! Espreita o desafio abaixo e descobre o que te espera.'
    ],
    1: [
      'Às vezes assume-se que quem vive no mesmo espaço partilha a mesma cultura, mas isso, muitas vezes, não é verdade. Foi por isso que alguns alunos e alunas não gostaram do “dia da nossa cultura” - sentiram que não representava a sua! Com a tua ajuda isso pode mudar. Vamos?',
      'Talvez já tenhas testemunhado situações semelhantes à que acabaste de ver. Este tipo de comentários faz com que os migrantes se sintam mal recebidos, situação difícil para quem chega a um sítio novo. Precisamos que entres em ação. Alinhas?',
      'Cada cultura tem muitas formas de comunicar para além da sua língua - gestos, cumprimentos, rituais, etc. Quando não se conhecem estes códigos, geram-se mal-entendidos e conflitos que podiam ser evitados. Precisamos de ti para ajudar a resolver esta embrulhada!'
    ],
    2: [
      'O racismo surge porque existem pessoas que acreditam que há grupos raciais ou étnicos superiores a outros. Traz muitas consequências, uma delas é que algumas pessoas, de tanto ouvirem que são inferiores, podem deixar de sonhar! Ajudas-me a combater esta injustiça?',
      'Estereótipos à solta! Pois é, parece que precisamos de ti, mais uma vez. É urgente mostrar que as escolhas e crenças de cada pessoa devem ser respeitadas e a religião não é exceção! Vamos tratar disso? '
    ],
    3: [
      'Uau, lado a lado numa última aventura! Agora não serei eu a sugerir-te o que fazer. Vais tu decidir a melhor forma que tens para ajudar a resolver este problema, que é de todos nós! Das 4 opções abaixo, só tens de escolher uma! Terminas em grande com esta missão?'
    ]
  },
  'cuf-saude': {
    0: [
      'Intro',
      'Olá! Quando clicas na imagem, como acabaste de fazer, recebes toda a informação que precisas para conseguir alterar a tua história. A tua 1.ª missão é desvendar a razão desta grande aventura! Espreita o desafio abaixo e descobre o que te espera.'
    ],
    1: [
      'Isto de viver durante uma pandemia tem muito que se lhe diga: há medidas de prevenção que temos de cumprir para proteger a vida de todos na comunidade, mas ainda há quem não as conheça bem. Queres ajudar a que situações como as que acabámos de ver não se repitam?',
      'Há muitas informações falsas ou pouco rigorosas a circular sobre a pandemia. Isso pode fazer com que as pessoas se ponham em risco ou não confiem nas informações apresentadas pelos sistemas de saúde. É urgente encontrar e levar a informação certa à comunidade. Aventuras-te?',
      'Neste tempo meio louco muitas pessoas ficam ansiosas e sentem-se afastadas de quem mais gostam. Mas não tem de ser assim. Vamos provar que há amor na pandemia?'
    ],
    2: [
      'Estar fechado entre 4 paredes pode gerar ansiedade a muitas pessoas. As preocupações e a tensão aumentam! É preciso dar a volta a isto! Aceitas o desafio?',
      'Às vezes temos receio e bloqueamos, como estátuas. Com esta pandemia, muitas pessoas estão nessa situação. Não sabem o que fazer e têm medo de se pôr em risco ou colocar outros em risco. Vamos ajudá-las a sentir-se mais seguras?'
    ],
    3: [
      'Uau, lado a lado numa última aventura! Agora não serei eu a sugerir-te o que fazer. Vais tu decidir a melhor forma que tens para ajudar a resolver este problema, que é de todos nós! Das 4 opções abaixo, só tens de escolher uma! Terminas em grande com esta missão?'
    ]
  },
  'igualdade-genero': {
    0: [
      'Intro',
      'Olá! Quando clicas na imagem, como acabaste de fazer, recebes toda a informação que precisas para conseguir alterar a tua história. A tua 1.ª missão é desvendar a razão desta grande aventura! Espreita o desafio abaixo e descobre o que te espera.'
    ],
    1: [
      'Alcançar a Igualdade de Género é uma preocupação nos dias de hoje, mas o desconhecimento nesta área só dificulta! Vamos dar a volta a isto e provar que o conhecimento é amigo da compreensão?',
      'Talvez já tenhas testemunhado situações semelhantes às que acabaste de ver. Parece que precisamos de mudar mentalidades - é urgente entender que as vontades e escolhas de cada pessoa devem ser respeitadas! Vamos tratar disso?',
      'A luta pela Igualdade de Género já percorreu um longo caminho, mas ainda não terminou a viagem! Ainda há muitos passos a dar. Juntas-te a esta missão?'
    ],
    2: [
      'Género e Orientação Sexual na linha da frente da discriminação?! Isto traz tantas consequências que, quem passa por isso, às vezes prefere ser invisível... É preciso dar a volta a isto! Conto contigo?',
      'Parece que o amor foi pelos ares! Não há respeito, confiança nem alegria nestas relações. Ajudamos a celebrar o amor sem violência?'
    ],
    3: [
      'Uau, lado a lado numa última aventura! Agora não serei eu a sugerir-te o que fazer. Vais tu decidir a melhor forma que tens para ajudar a resolver este problema, que é de todos nós! Das 4 opções abaixo, só tens de escolher uma! Terminas em grande com esta missão?'
    ]
  },
  'educacao-ambiental': {
    0: [
      'Intro',
      'Olá! Quando clicas na imagem, como acabaste de fazer, recebes toda a informação que precisas para conseguir alterar a tua história.  A tua 1.ª missão é desvendar a razão desta grande aventura! Espreita o desafio abaixo e descobre o que te espera.'
    ],
    1: [
      'A ação humana tem destruído a floresta e diminuído a quantidade de espécies! Algumas podem mesmo deixar de existir para sempre (extinção)! Isto leva a que outros seres vivos fiquem sem alimento e provoca o aparecimento de pragas e de um ar muito mais poluído, que prejudica a vida de todo o Planeta. Temos que lutar pela biodiversidade. Ajudas-me',
      'Os nossos hábitos estão a prejudicar o ambiente. Os transportes que usamos, os objetos que compramos, a roupa que vestimos e até a nossa alimentação, têm causado o aquecimento global e alterações climáticas graves, como vagas de calor, tempestades ou secas. Preciso de ti para alterar isto. Alinhas?',
      'Será que todos sabemos separar e dar valor aos resíduos (lixo)? A falta de conhecimento leva ao desperdício, à poluição e à contaminação da água e dos solos. Os 5 R’s - REPENSAR, RECUSAR, REDUZIR, REUTILIZAR e RECICLAR, podem ajudar. Vamos usar a força “R”?'
    ],
    2: [
      'Muitos dos nossos alimentos resultam de uma agricultura intensiva que destrói os solos, como, por exemplo, o cultivo de um só produto em grandes terrenos ou a utilização de substâncias não naturais. Temos de semear o futuro de forma diferente. Conto contigo?',
      'A água do planeta tem que ser preservada e protegida! Se isso não acontecer, há graves consequências para as pessoas e para o ambiente. A água potável deixa de existir, há mais doenças, mais poluição e destruição de biodiversidade. Vamos dar a volta a isto?'
    ],
    3: [
      'Uau, lado a lado numa última aventura! Agora não serei eu a sugerir-te o que fazer. Vais tu decidir a forma como queres ajudar o planeta e resolver este problema, que é de todos nós! Das 4 opções abaixo, só tens de escolher uma! Terminas em grande com esta missão?'
    ]
  },
  'girl-move': {
    0: [
      'Intro',
      'Olá! Quando aceitas o desafio, como acabaste de fazer, recebes toda a informação que precisas para conseguir mudar a tua história. Espreita o desafio e descobre o que te espera.'
    ],
    1: [
      'Oh não... Muitas meninas não vão à escola! Não pode ser! Tens que lhes mostrar que a escola também é para as meninas e que o futuro delas depende disso. Estás pronta?',
      'Muitas pessoas não sabem, mas... contaminam a água do rio que, mais tarde, é a água que utilizamos nas nossas casas. É muito mau para a nossa saúde e para o planeta. Precisamos aqui da ajuda de uma heroína!',
      'Ups, parece que as pessoas não estão a saber cuidar da própria saúde! Só uma heroína como tu pode ajudar a resolver este problema. Vamos?'
    ],
    2: [
      'Fico muito triste quando vejo isto: pessoas a desrespeitar as escolhas e o corpo de outras meninas como nós. Estas manas precisam da  ajuda de alguém. Foste a escolhida, força!',
      'Muito lixo! Em todo o lado! Se continuarmos assim vamos viver num mundo de lixo e isso não pode acontecer. Eu tenho um plano, mas preciso de ti! Anda!'
    ],
    3: [
      'Uau, juntas numa última aventura! Agora não serei eu a dizer o que deves fazer. Vais tu decidir o melhor caminho para ajudar a resolver este problema da sustentabilidade, que é de todos nós! Das 4 opções, só tens de escolher uma! Que tal cumprir esta missão com sucesso?'
    ]
  }
}

export const PROBLEM_NAMES = {
  'desenvolvimento-sustentavel': {
    0: [
      'Introdução',
      'O início da aventura'
    ],
    1: [
      'Os 17 desconhecidos',
      'A circulaR',
      'Mexe-te!'
    ],
    2: [
      'A aquecer',
      'Desigual'
    ],
    3: [
      'A Grande Missão'
    ]
  },
  'interculturalidade': {
    0: [
      'Intro',
      'O início da aventura'
    ],
    1: [
      'A nossa cultura?',
      'Os muros que nos separam',
      'Nem só a falar a gente se entende!'
    ],
    2: [
      'Sonhos rasurados',
      'Estereótipos à solta'
    ],
    3: [
      'A Grande Missão'
    ]
  },
  'cuf-saude': {
    0: [
      'Intro',
      'O início da aventura'
    ],
    1: [
      'Ubunto - Eu sou porque tu és',
      'Aqui há gato!',
      'Não há amor na pandemia'
    ],
    2: [
      'Entre 4 paredes',
      'Pára Tudo'
    ],
    3: [
      'A Grande Missão'
    ]
  },
  'igualdade-genero': {
    0: [
      'Intro',
      'O início da aventura'
    ],
    1: [
      'Se não nos conhecermos, não nos entendemos',
      'Estereótipos à solta!',
      'Viagem desigual'
    ],
    2: [
      'Os invisíveis',
      'O amor não está no ar!'
    ],
    3: [
      'A Grande Missão'
    ]
  },
  'educacao-ambiental': {
    0: [
      'Intro',
      'O início da aventura'
    ],
    1: [
      'A floresta chama-te!',
      'Rebenta a bolha!',
      'Força “R” precisa-se!'
    ],
    2: [
      'Sem chão.',
      'A meter água.'
    ],
    3: [
      'A Grande Missão'
    ]
  },
  'girl-move': {
    0: [
      'Intro',
      'O início da aventura'
    ],
    1: [
      'A Escola é nice!',
      'Água é vida!',
      'Cheia de saúde!'
    ],
    2: [
      'Super-heroínas',
      'Lixo Zero'
    ],
    3: [
      'A grande missão!'
    ]
  }
};

export const MISSION_DESCRIPTIONS = {
  1: 'Entrem na aventura para se tornarem os próximos heróis e heroínas!',
  2: '80% dos heróis e heroínas - Superar o Capítulo Intro',
  3: '10% dos heróis e heroínas - Terminar 2 aventuras!',
  4: '50% dos heróis e heroínas  - Terminar 1 aventura!',
  5: 'Pelo menos 1 herói ou heroína -  Apresentar à sua turma os resultados de um desafio!',
  6: '20% dos heróis e heroínas -  Terminar 4 aventuras!',
  7: '40% dos heróis e heroínas  - Terminar 2 aventuras!',
  8: 'Pelo menos 1 herói ou heroína  - Superar "A Grande Missão"',
  9: '75% dos heróis e heroínas  - terminar 1 aventura!',
  10: 'Um grupo de heróis -  juntar-se para fazer um desafio!',
  11: '40% dos heróis e heroínas  - terminar 4 aventuras!',
  12: '80% dos heróis e heroínas -  terminar 2 aventuras!',
  13: '35% dos heróis e heroínas  - superar "A Grande Missão"!',
  14: '90% dos heróis e heroínas  - completar 1 aventura do capítulo 2!',
  15: 'O Professor Gamaster - Realizar pelo menos 1 desafio de ação com a sua turma!'
}