import * as React from "react"

import IconCheck from '../../assets/images/icon-check.svg'
import Background from '../../assets/images/background-button.svg'

class AppButtonIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iconUrl: ""
    }
  }
  
  /* PROPS

    modifiers: [CSS classes]
    icon: [icon image to display]
    name: [text name for accessibility purposes] 
    verifyIcon: [verify icon on the top-right corner]
    disabled: [disable the button as native HTML]
    onClick: [function to trigger on button click]

  ---- */

  componentDidMount() {
    // Render icon described as prop
    // Icons available: accao, caixa-ferramentas, descoberta, dia-diferente, influencer
    //                  left, logout, mobilizacao, teu-programa, tempo-antena, right
    import(`../../assets/images/icon-${this.props.icon}.svg`).then(image => {this.setState({iconUrl: image.default})})
  }

  themeClass(modifiers) {
    return `c-button c-button--icon ${modifiers ? modifiers : ""}`
  }

  showVerify(verify) {
    // Render verify icon at the top-right corner of the button
    if (verify) return (
      <img 
        src={IconCheck} 
        alt="Desafio completo" 
        className="c-button__verify"
      />
    )
  }

  render() {
    return (
      <button 
        className={this.themeClass(this.props.modifiers)} 
        disabled={this.props.disabled} 
        name={this.props.name}
        onClick={this.props.onClick}
      >
        <img className="c-button__background" src={Background} alt=""/>
        <img className="c-button__img" src={this.state.iconUrl} alt=""/>
        {this.showVerify(this.props.verifyIcon)}
      </button>
    )
  }
}

export default AppButtonIcon
