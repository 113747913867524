import * as React from "react"
import CloseIcon from "../../assets/images/icon-close.svg"

class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    }
  }

  toggleModal(value) {
    this.setState({ modalOpen: value})
  }

  render() {
    return(
      <React.Fragment>
        <button
          className="c-button c-button--big"
          onClick={() => this.toggleModal(true)}
        >
          {this.props.triggerButton}
        </button>
        <div className="o-modal-overlay-wrapper" open={this.state.modalOpen}>
          <div className="o-modal o-modal--small" >
            <div
              className="o-modal__close"
              onClick={() => this.toggleModal(false)}>
              <img src={CloseIcon} alt="close"/>
            </div>
            <div className="o-modal__header">
              <h1 className="">
                {this.props.title}
              </h1>
            </div>
            <div className="o-modal__body u-text-c">
              <p>{this.props.description}</p>
              <br/>
              <p className="u-tw-600">{this.props.confirmQuestion}</p>
            </div>
            <div className="o-modal__footer">
              <div className="o-modal__actions">
                  <button 
                    className="c-button"
                    onClick={() => this.toggleModal(false)}
                  >
                    {this.props.cancelButton}
                  </button>
                  <button 
                    className="c-button"
                    onClick={this.props.confirmAction}
                  >
                    {this.props.confirmButton}
                  </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ConfirmationModal
