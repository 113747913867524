import React from 'react';
import IconCheck from '../../assets/images/icon-check.svg'

class AvatarCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      avatarThemes: {
        1 : "",
        2 : "",
        3 : "",
        4 : "",
        5 : "",
        6 : "",
      }
    }
  }
  componentDidMount(){
    import(`../../assets/images/${this.props.game}/avatars/evo/avatar-1--id-card.svg`).then(image => {this.setState({avatarThemes: {...this.state.avatarThemes, 1: image.default}}) })
    import(`../../assets/images/${this.props.game}/avatars/evo/avatar-2--id-card.svg`).then(image => {this.setState({avatarThemes: {...this.state.avatarThemes, 2: image.default}}) })
    import(`../../assets/images/${this.props.game}/avatars/evo/avatar-3--id-card.svg`).then(image => {this.setState({avatarThemes: {...this.state.avatarThemes, 3: image.default}}) })
    import(`../../assets/images/${this.props.game}/avatars/evo/avatar-4--id-card.svg`).then(image => {this.setState({avatarThemes: {...this.state.avatarThemes, 4: image.default}}) })
    import(`../../assets/images/${this.props.game}/avatars/evo/avatar-5--id-card.svg`).then(image => {this.setState({avatarThemes: {...this.state.avatarThemes, 5: image.default}}) })
    import(`../../assets/images/${this.props.game}/avatars/evo/avatar-6--id-card.svg`).then(image => {this.setState({avatarThemes: {...this.state.avatarThemes, 6: image.default}}) })
  }

  themeClass(modifiers) {
    return `c-avatar-card ${modifiers ? modifiers : ""}`
  }

  render() {
    return (
      <figure className={this.themeClass(this.props.modifiers)}>
        {this.props.verifyIcon &&
          <img src={IconCheck} alt="Completo" className="c-avatar-card__verify" />
        }
        <img
          src={this.state.avatarThemes[this.props.theme]}
          alt={this.props.label}
        />
        <figcaption className="c-avatar-card__label">{this.props.label}</figcaption>
      </figure>
    )
  }
}

export default AvatarCard
