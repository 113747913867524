export const structureGameChallenges = (challengeDocs) => {
  let levels = [
      { },
      { },
      { },
      { }
    ];

  challengeDocs.forEach((challengeDoc) => {
    let challenge = challengeDoc.data();
    let challengeId = challengeDoc.id;

    let level = challenge.level;
    let problem = challenge.problem;

    if(!levels[level][problem]) {
      levels[level][problem] = [];
    }

    challenge.id = challengeId;

    levels[level][problem][challenge.order] = challenge;
  });

  return levels;
}

export const buildInitialGameState = (structuredChallenges) => {
  let initialGameState = {
    avatar: "evo",
    scoreboard: {
      "accao": 0,
      "descoberta": 0,
      "mobilizacao": 0,
    },
    levels: []
  };

  structuredChallenges.forEach((level, idx) => {
    let levelObject = {
      order: idx,
      unlocked: (idx === 0 ? true : false),
      completed: false,
      problems: []
    };

    // EDGE CASE: If we're on level 0, create a dummy problem for the first page
    if(idx === 0){
      let dummyProblem = {
        name: "1",
        nChallengesCompleted: 0,
        completed: true,
        challenges: []
      };

      levelObject.problems.push(dummyProblem);
    }

    Object.keys(level).forEach((problem, idx) => {
      let problemObject = {
        name: problem,
        nChallengesCompleted: 0,
        completed: false,
        avatar: null,
        challenges: []
      };

      level[problem].forEach((challenge, idx) => {
        problemObject.challenges.push({
          id: challenge.id,
          type: challenge.type,
          completed: false,
          hidden: challenge.startsHidden
        });
      });

      levelObject.problems.push(problemObject);
    });

    initialGameState.levels.push(levelObject);
  });


  return initialGameState;
}