import React from "react"
import spotCIcon from "../../assets/images/spotC-icon.svg"
import LogoutButton from "../game/appButtonLogout.js"

class TopBar extends React.Component {
  render() {
    return(
      <div className="o-dashboard__topbar" data-flout="row-- align-center-- justify-center--">
        <div className="o-dashboard__topbar__content" data-flout="row-- justify-between-- align-center--">
          <div data-flout="row-- align-center--" data-flitem="grow--">
            <img src={spotCIcon} alt="SPOT Cidadania icon"
              className="o-dashboard__logo"
            />
            <p className="u-ml">SPOT Cidadania</p>
          </div>
          <div data-flout="row-- align-center-- justify-between--justify-end@sm--" data-flitem="grow--">
            <div data-flout="row--" className="u-mr-2x">
              {this.props.teacherName && <p className="u-mr">{this.props.teacherName}</p>}
              {this.props.activeClassName && <p>| {this.props.activeClassName}</p>}
            </div>
            <LogoutButton text="Sair" noIcon/>
          </div>
        </div>
      </div>
    )
  }
}


export default TopBar
