import * as React from "react"

class Loader extends React.Component {
  render() {
    return(
      <div className="c-loader-wrapper">
        <div className="c-loader">
          <div className="c-loader__img"/>
          <div className="c-loader__text">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 231.84 68.88"
              preserveAspectRatio="none"
            >
              <defs>
                <style>
                  {".prefix__cls-1{isolation:isolate}.prefix__cls-3{fill:#0b0b0b}"}
                </style>
              </defs>
              <g
                id="prefix__Rectangle_1_copy_20"
                data-name="Rectangle 1 copy 20"
                className="prefix__cls-1"
              >
                <g id="prefix__Rectangle_1_copy_20-2" data-name="Rectangle 1 copy 20">
                  <path
                    d="M13.35 68.33c-4.27 0-7-.86-8.93-2.79-2.12-2.15-3.13-5.61-3.07-10.6v-7.87c0-5.94 0-12.67.25-17.85.28-6.66 2.81-9.33 16.44-11.11 16.2-2.11 31.56-2.29 52.82-2.55 12.16-.14 25.94-.31 43.17-.85a3.83 3.83 0 003.32-1.56c1.35-2.08 0-5.4 0-5.54-.39-1.28-.95-3.08-.57-3.6.11-.15.35-.22.72-.22 1 0 2.79.54 5.64 1.71 1.4.58 6.62 3.16 6.65 5.31 0 2.45-2.78 3.59-2.8 3.61l-2.41.95h2.59c.61 0 61 .23 88.24 4l.76.1c4.73.61 14.59 1.89 14.74 11.37l.4 24.4c.08 4.81-.78 8.07-2.63 10-1.48 1.5-3.69 2.23-6.74 2.23h-.47c-33.42-.79-68.5-1.2-101.44-1.2-38.65 0-72.78.57-98.72 1.67-1 0-2 .11-3 .19-1.63.08-3.31.2-4.96.2z"
                    fill="#babcbe"
                  />
                  <path
                    className="prefix__cls-3"
                    d="M117.53 4.29c.92 0 2.7.55 5.45 1.67 2.13.88 6.32 3.28 6.34 4.85 0 2.09-2.47 3.13-2.49 3.14L122 15.86h5.19c.6 0 61 .23 88.17 4l.76.1c4.6.6 14.17 1.84 14.31 10.89l.23 13.15.18 11.28c.08 4.67-.74 7.81-2.49 9.59-1.38 1.4-3.47 2.08-6.38 2.08h-.46c-33.41-.79-68.49-1.21-101.43-1.21-38.66 0-72.81.58-98.76 1.68-1 0-2 .11-3 .19-1.62.12-3.31.24-4.93.24-4.13 0-6.77-.82-8.58-2.64-2-2-3-5.4-2.92-10.24V47.1c0-5.94 0-12.67.25-17.84.27-6.45 2.62-8.89 16-10.63 16.18-2.11 31.52-2.29 52.76-2.55 12.17-.14 26-.31 43.19-.85a4.27 4.27 0 003.72-1.79c1.43-2.19.25-5.43 0-6a9.86 9.86 0 01-.65-3.14 1.27 1.27 0 01.32 0m0-1c-1.88 0-1.49 1.67-.61 4.51 0 0 2.52 6.25-2.85 6.41-48.14 1.51-70.76.11-96 3.41C4.87 19.33 1.46 21.84 1.15 29.2.83 36.81.94 47.51.85 54.94c-.14 11.93 5.88 13.89 12.5 13.89 2.69 0 5.49-.32 8-.43 27.47-1.16 62.17-1.68 98.72-1.68 33.62 0 68.81.44 101.4 1.21h.53c7 0 10-3.66 9.87-12.69-.12-7.23-.27-16.86-.4-24.4-.17-10.48-11.74-11.38-15.93-12-27.55-3.81-88.31-4-88.31-4s3.16-1.25 3.12-4.08-6.62-5.62-7-5.76c-2.92-1.2-4.75-1.75-5.83-1.75z"
                  />
                </g>
              </g>
              <g
                id="prefix__Rectangle_1_copy_21"
                data-name="Rectangle 1 copy 21"
                className="prefix__cls-1"
              >
                <g id="prefix__Rectangle_1_copy_21-2" data-name="Rectangle 1 copy 21">
                  <path
                    d="M12.63 65c-4.2 0-6.9-.84-8.76-2.72C1.8 60.15.82 56.74.88 51.83c0-2.36 0-5.06.05-7.87 0-5.94 0-12.67.25-17.85C1.46 19.61 4 17 17.4 15.24c16.19-2.11 31.54-2.24 52.79-2.55 12.16-.14 26-.31 43.18-.85a4.05 4.05 0 003.52-1.68c1.42-2.18.06-5.62 0-5.76-.31-1-.92-2.94-.61-3.36a.75.75 0 01.52-.12 18.67 18.67 0 015.54 1.69c2.26.93 6.48 3.37 6.5 5.08 0 2.26-2.62 3.36-2.65 3.37l-3.55 1.44h3.83c.6 0 61 .23 88.21 4l.76.1c4.66.61 14.37 1.87 14.52 11.14l.4 24.4c.08 4.74-.76 7.93-2.56 9.76-1.43 1.46-3.58 2.16-6.56 2.16h-.47c-33.42-.79-68.49-1.2-101.44-1.2-38.65 0-72.79.57-98.73 1.67-1 0-2 .12-3 .19-1.6.12-3.34.28-4.97.28z"
                    fill="#fff"
                  />
                  <path
                    className="prefix__cls-3"
                    d="M117 1.67a21.39 21.39 0 015.1 1.64c2.72 1.12 6 3.33 6 4.39 0 1.74-2.16 2.66-2.17 2.67l-7.23 2.87h7.78c.6 0 61 .23 88.11 4l.76.1c7.13.93 13.75 2.83 13.87 10.4l.4 24.4c.08 4.53-.69 7.55-2.35 9.23-1.28 1.3-3.25 1.93-6 1.93h-.45c-33.42-.79-68.49-1.21-101.44-1.21-38.67 0-72.83.58-98.78 1.68-1 0-2 .12-3 .19-1.62.12-3.29.24-4.9.24-4 0-6.52-.77-8.22-2.49-1.9-1.92-2.83-5.25-2.78-9.89 0-2.36 0-5 .05-7.87 0-5.93 0-12.65.25-17.82C2.19 20 4.48 17.68 17.5 16c16.14-2.12 31.5-2.3 52.7-2.56 12.16-.14 26-.31 43.2-.85a4.76 4.76 0 004.12-2c1.54-2.37.34-5.77.1-6.39a17.78 17.78 0 01-.62-2.53m-.16-1.5c-1.88 0-1.49 1.67-.61 4.51 0 0 2.52 6.25-2.85 6.41-48.14 1.51-70.76.11-96 3.41C4.15 16.21.74 18.72.43 26.08.11 33.69.22 44.39.13 51.82 0 63.75 6 65.71 12.63 65.71c2.69 0 5.49-.32 8-.43 27.47-1.16 62.17-1.68 98.72-1.68 33.62 0 68.81.44 101.4 1.21h.49c7 0 10-3.66 9.87-12.69-.12-7.23-.27-16.86-.4-24.4-.17-10.48-11.74-11.38-15.93-12-27.55-3.81-88.31-4-88.31-4s3.16-1.25 3.12-4.08-6.62-5.62-7-5.76c-2.87-1.16-4.7-1.71-5.78-1.71z"
                  />
                </g>
              </g>
            </svg>
            <span>
              {this.props.submitting ?
                "a submeter"
                :
                (this.props.text ? this.props.text : "a carregar")
              }
            </span>
            <span className="u-ml">. . .</span>
          </div>
          {this.props.submitting && <p className="c-loader__extra-info">por favor aguarda</p> }
        </div>
      </div>
    )
  }
}

export default Loader
