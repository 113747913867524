import { Component } from "react";
import { Redirect } from "react-router-dom";
import { auth } from "../middlewares/firebase";
import Loader from '../components/game/loader'

class LoginConfirmation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      confirmed: false
    }
  }

  componentDidMount() {
    if(auth.isSignInWithEmailLink(window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');

      if(!email) {
        // In this case it means the user opened the link in a different device
        // TODO: ???
      }

      auth.signInWithEmailLink(email, window.location.href)
        .then((result) => {
          window.localStorage.removeItem('emailForSignIn');
          this.setState({
            loading: false,
            confirmed: true
          });
        })
        .catch((error) => {
          console.error(error);
          this.setState({
            loading: false,
            confirmed: false
          })
        });
    }
  }

  render() {
    if(this.state.loading) {
      return <Loader/>
    } else {
      if(this.state.confirmed) {
        return <Redirect to="/"/>
      } else {
        return <Redirect to="/login-professor"/>
      }
    }
  }
}

export default LoginConfirmation;