import * as React from "react"
import { auth } from "../../middlewares/firebase";
import IconLogout from '../../assets/images/icon-logout.svg'

function AppButtonLogout(props) {
  return (
    <div className="c-button c-button--logout" onClick={(e) => auth.signOut()}>
      {!props.noIcon && <img src={IconLogout} alt=""/>}
      <span>{props.text}</span>
    </div>
  )
}

export default AppButtonLogout
