import React from 'react';
import Text from '../components/game/text.js';
import { Link, withRouter } from 'react-router-dom';
import AppButtonIcon from '../components/game/appButtonIcon.js';
import BdPanel from '../components/level/bdPanel.js'
import Challenge from '../components/challenge/modal.js'

import { PROBLEM_DESCRIPTIONS } from '../utils/copy.js';
import { CLASS_STATUS } from "../utils/constants"
import { auth, getStepsForChallenge, submitAnswer, firestore } from '../middlewares/firebase';
import { StudentDataContext } from '../providers/StudentDataProvider.js';

class Problem extends React.Component {
  static contextType = StudentDataContext;

  constructor(props) {
    super(props);
    this.state = {
      selectedChallenge: 0,
      selectedChallengeAnswers: null,
      image: "placeholder",
      stepsForModal: [],
      showChallenge: false,
      activeStep: 0,
      activeStepAnswer: "",
      initialSolvedStatus: 0,
      answerSubmitted: false,
      challengesUpdated: false
    }

    this.submitChallengeAnswer = this.submitChallengeAnswer.bind(this);
  }

  async componentDidMount() {
    var defaultSelectedChallenge
    if (this.props.level === 3) {
      defaultSelectedChallenge = null
    } else {
      if (this.props.challenges.length === 1) {
        defaultSelectedChallenge = 0
      } else {
        defaultSelectedChallenge = this.props.challenges.reduce(function(acc, cur, key) {

          if (acc.unlocked) acc = 0
          if (cur.unlocked) acc = key

          return acc
        })
      }
    }
    this.setSelectedChallenge(defaultSelectedChallenge)

    this.setState({initialSolvedStatus: this.props.challenges.filter((obj) => obj.completed === true).length})
  }

  componentDidUpdate(nextProps) {
    if(nextProps.challenges !== this.props.challenges) {
      this.setState({ challengesUpdated: true })
    }
    // Redirect to the level page if the last challenge is completed (or any challenge is completed on level 3)
    if(this.state.answerSubmitted && !this.state.showChallenge && this.state.challengesUpdated) {
      if(((this.state.selectedChallenge + 1 === this.props.challenges.length)) || (this.props.level === 3)) {
        this.props.history.push({
          pathname: '/nivel',
          state: {
            anyCompleted: true,
            justCompleted: this.props.level > 0 ? true : false,
          }
        })
      } else {
        // Reset the answerSubbmited to false if the problem isn't completed
        this.setSelectedChallenge(this.state.selectedChallenge + 1)
        this.setState({ answerSubmitted: false, challengesUpdated: false })
      }
    }
  }

  challengeButtonsTheme(unlocked, selected) {
    // Define theme classes for challenge buttons according to challenge state (unlocked and/or selected)
    var state
    if(unlocked && selected) {
      state = "c-button--selected"
    } else if (!unlocked) {
      state = "c-button--locked"
    }
    return `c-button--expanded ${state}`
  }

  renderChallengeButtons(challenges) {
    // Render each challenge button according to challenges defined on state
    var buttonsEl =  challenges.map(function(challenge, key) {
      return (
        <AppButtonIcon
          key={key}
          modifiers={this.challengeButtonsTheme(challenge.unlocked, this.state.selectedChallenge === key)}
          icon={challenge.type}
          name={`Desafio ${challenge.type}`}
          verifyIcon={challenge.completed}
          pointerIcon={this.state.selectedChallenge === key}
          onClick={() => this.setSelectedChallenge(key)}
        />
      )
    }, this)
    return buttonsEl
  }

  setSelectedChallenge(index) {
    this.setState({selectedChallenge: index}, async () => {

      let submissions = (await firestore.collection(`students/${auth.currentUser.uid}/answers`)
      .get()).docs.map(doc => doc.data())
      .filter((submission) => submission.level === this.props.level)
      .filter((submission) => submission.problem === this.props.problem);
      let selectedChallengeAnswers = null;
      if (this.props.level === 3){
        if (this.props.challenges[0].completed) {
          selectedChallengeAnswers = submissions[0].answers
        }
      } else if (this.props.challenges[this.state.selectedChallenge].completed){
        selectedChallengeAnswers = submissions.filter((submission) => parseInt(submission.order) === this.state.selectedChallenge)[0].answers
      }
      this.setState({ selectedChallengeAnswers: selectedChallengeAnswers })

    })
  }

  getActiveStepAnswer(){
    let activeStepAnswer = ""
    if (this.props.challenges[this.state.selectedChallenge].completed) {
      let questionSteps = this.state.stepsForModal.filter((step) => step.type === "question").sort((a, b) => (parseInt(a.order) - parseInt(b.order)))
      let activeStep = this.state.stepsForModal[this.state.activeStep]
      if (activeStep.type === "question") {
        let activeStepAnswerIndex = questionSteps.findIndex((step, i) => step === activeStep)

        if (activeStepAnswerIndex > 0) {
          activeStepAnswer = this.state.selectedChallengeAnswers.sort((a, b) => (parseInt(a.order) - parseInt(b.order)))[activeStepAnswerIndex]
        } else {
          activeStepAnswer = this.state.selectedChallengeAnswers.sort((a, b) => (parseInt(a.order) - parseInt(b.order)))[0]
        }
      } else {
        activeStepAnswer = "";
      }
    }
    this.setState({ activeStepAnswer: activeStepAnswer ? activeStepAnswer.value : "" });
  }

  // toogle challenge visibility
  toggleModal(value) {
    this.setState({ showChallenge: value , activeStep: 0})
    value ? this.getActiveStepAnswer() : this.setState({ activeStepAnswer: "" })
  }
  // Increase challenge step
  nextStep() {
    let nextActiveStep = this.state.activeStep + 1;
    //when challenge complete and next step not last and next step type === submit
    if (this.props.challenges[this.state.selectedChallenge].completed
        && nextActiveStep < this.state.stepsForModal.length - 1
        && this.state.stepsForModal[nextActiveStep].type === "submit") {
      // skip submit step
      nextActiveStep = this.state.activeStep + 2 ;

    }
    this.setState({ activeStep: nextActiveStep }, () => {
        this.getActiveStepAnswer()
    })
  }
  // Decrease challenge step
  previousStep() {
    let previousActiveStep = this.state.activeStep - 1;
    //when challenge complete and previous step not last and previous step type === submit
    if (this.props.challenges[this.state.selectedChallenge].completed
        && previousActiveStep < this.state.stepsForModal.length - 1
        && this.state.stepsForModal[previousActiveStep].type === "submit") {
      // skip submit step
      previousActiveStep = this.state.activeStep - 2 ;

    }
    this.setState({ activeStep: previousActiveStep }, () => {
        this.getActiveStepAnswer()
    })
  }

  submitChallengeAnswer(answers) {
    this.setState({ answerSubmitted: true })
    return submitAnswer(
      auth.currentUser.uid,
      this.props.level,
      this.props.problem,
      this.props.challenges[this.state.selectedChallenge],
      answers
    )
  }

  updateLastProblem() {
    // Reset selectedChallenge when the level 3 problem is solved
    if(this.props.level === 3){
      this.setSelectedChallenge(0)
    }
  }

  async openChallenge() {
    let challenge = this.props.challenges[this.state.selectedChallenge]
    let steps = await getStepsForChallenge(challenge.id);

    this.setState({
      stepsForModal: steps,
      showChallenge: true,
      activeStep: 0
    }, () => this.getActiveStepAnswer());
  }

  render() {
    const selectedChallenge = this.state.selectedChallenge !== null ? this.props.challenges[this.state.selectedChallenge] : this.props.challenges[0];
    const challengeDescription = selectedChallenge.completed ? selectedChallenge.descriptionCompleted : selectedChallenge.description;

    return (
      <div className="o-app__bg-dots">
        <div className="o-app__frame">
          <section className="o-view">
            <div data-flout="expand-- justify-center--" className="o-app__container">
              <div
                  className="o-bd__panel o-bd__panel--1"
                >
                 <BdPanel
                    game= {this.props.game}
                    level={this.props.level}
                    problem={this.props.problem}
                    avatarTheme={this.context.student.avatar}
                    panel={1}
                    actionPanel={3}
                    completed={false}
                  />
              </div>
            </div>
            <div data-flout="expand-- justify-center--" className="o-app__container">
              <h1 className="c-text-banner u-mb">
                {this.props.name}
              </h1>
              <Text
                content={PROBLEM_DESCRIPTIONS[this.props.game][this.props.level][this.props.problem - 1]}
                modifiers="u-mb-3x"
              />
            </div>
            <div className="o-app__container u-mb">
              <div className="c-input-group c-input-group--challenges u-mb" data-flout="justify-between-- expand--">
                {this.renderChallengeButtons(this.props.challenges)}
              </div>
              <div data-flout="expand-- justify-center--">
                {this.state.selectedChallenge != null &&
                  <Text
                    content={selectedChallenge.title+' - '+challengeDescription}
                    level={this.props.level}
                    challenge={this.state.selectedChallenge + 1}
                  />
                }
              </div>
            </div>
            <div className="o-app__container u-pb-2x" data-flout="justify-between-- expand--">
              <Link to={
                this.props.onBackRedirectTo ?
                {
                  pathname: this.props.onBackRedirectTo
                }
                :
                {
                  pathname: '/nivel',
                  state: {
                    justCompleted: this.props.level > 0 && this.props.challenges.filter((obj) => obj.completed === true).length > this.state.initialSolvedStatus ? true : false ,
                  }
                }
              }>
                <button
                  className="c-button"
                >
                  Voltar
                </button>
              </Link>
              { /* button open-chalenge on click */ }
              {this.state.selectedChallenge != null &&
              (selectedChallenge.completed ?
                <button
                  className="c-button"
                  onClick={() => this.openChallenge()}
                >
                  Ver
                </button>
                :
                <button
                  disabled={this.context.activeClass.status === CLASS_STATUS.CLOSED}
                  className="c-button"
                  onClick={() => this.openChallenge()}
                >
                  Go!
                </button>
              )}
            </div>
          </section>
          {/** dynamicly render challenge selceted **/}
          {this.state.selectedChallenge != null &&
          <Challenge
            challenge={selectedChallenge}
            steps={this.state.stepsForModal}
            onAnswerSubmit={this.submitChallengeAnswer}
            isOpen={this.state.showChallenge}
            closeChallenge={() => this.toggleModal(false)}
            updateLastProblem={() => this.updateLastProblem()}
            activeStep={this.state.activeStep}
            activeStepAnswer={this.state.activeStepAnswer}
            nextStep={() => this.nextStep()}
            previousStep={() => this.previousStep()}/>}
        </div>
      </div>
    )
  }
}

export default withRouter(Problem)
