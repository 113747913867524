import { Component } from 'react'
import { auth } from '../middlewares/firebase'

class Logout extends Component {

  componentDidMount() {
    auth.signOut();
  }

  render() {
    return null
  }
}

export default Logout;