import * as React from "react"
import Text from "./text.js"
import Input from "./input.js"

class Step extends React.Component {
  render() {
    const step = this.props.step
    return(
      <div className="o-challenge__step">
        {step.image &&
          <img className="o-challenge__img" alt="desafio" src={step.image}></img>
        }
        {step.youtube &&
          <iframe className="o-challenge__video" width="530" height="320" title="video-desafio" src={step.youtube} />
        }
        { step.text &&
          <Text text={step.text}/>
        }
        { step.type === "question" &&
          <Input
            label={step.input.label}
            type={step.input.type}
            value={this.props.value}
            onChange={this.props.onAnswerUpdate}
            challengeCompleted={this.props.challengeCompleted}
          />
        }
      </div>
    )
  }
}

export default Step
