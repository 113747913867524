import React from "react"
import { Link } from "react-router-dom"
import TopBar from "../components/dashboard/topbar.js"
import TerminateModal from "../components/dashboard/confirmationModal.js"
import Missions from "../components/dashboard/missions.js"
import spotBoxImg from "../assets/images/caixa.png"
import bubblesLayer1Img from "../assets/images/bolhas-layer-1.png"
import bubblesLayer2Img from "../assets/images/bolhas-layer-2.png"
import gamemastersImg from "../assets/images/gamemasters.png"
import bdExampleImg from "../assets/images/bd-example.png"
import descobertaIcon from "../assets/images/icon-descoberta.svg"
import acaoIcon from "../assets/images/icon-accao.svg"
import mobilizacaoIcon from "../assets/images/icon-mobilizacao.svg"
import returnIcon from "../assets/images/icon-left.svg"
import availableSoonPdf from "../assets/docs/pdf-brevemente.pdf"
import { closeClass } from "../middlewares/firebase";
import { TeacherDataContext } from "../providers/TeacherDataProvider";
import { CLASS_STATUS } from "../utils/constants"

class TeacherDashboard extends React.Component {
  static contextType = TeacherDataContext;

  constructor(props) {
    super(props);
    this.state = {
      manualGamemasterPdf: "",
      matrizPedagogicaPdf: "",
      desafiosSolucoesPdf: "",
      formatoClassicoPdf: "",
      formatoRemotoPdf: "",
      formatoContraRelogioPdf: "",
      copySuccess: "",
      closingClass: false,
      video1: "https://www.youtube.com/embed/V3BBS4hFS50",
      video2: "https://www.youtube.com/embed/4Q1929-NV9c",
      video3: "https://www.youtube.com/embed/oBM6BwlM1Uo",
      video4: "https://www.youtube.com/embed/XfRJ_f4-8IQ",
      video5: "https://www.youtube.com/embed/nB0bX44PhKo",
      video6: "https://www.youtube.com/embed/4qMmr_E6tTY"
    }
  }

  copyToClipboard = (e) => {
    this.registerURL.select();
    document.execCommand("copy");
    e.target.focus();
    this.setState({ copySuccess: "link copiado!" });
    setTimeout(function(){
      this.setState({copySuccess: ""});
    }.bind(this),1500);
  }

  terminateGame = (e) => {
    this.setState({ closingClass: true })
    closeClass(this.context.classId).then(() => {
      this.setState({ closingClass: false })
    });
    e.preventDefault();
  }

  shortenStudentName(fullName) {
    let shortenedName = fullName;
    let names = fullName.split(" ");

    if (names.length > 2) { // bigger than 2 names: first two + last one
      shortenedName = `${names[0]} ${names[1]} ${names[names.length-1]}`
      if (shortenedName.length > 15) { // too long: first and last only
        shortenedName = `${names[0]} ${names[names.length-1]}`
      }
      return shortenedName;
    } else {
      return fullName;
    }
  }

  componentDidMount() {
    import(`../assets/docs/matriz-pedagogica-${this.context.activeClass.version}.pdf`).then(doc => {this.setState({matrizPedagogicaPdf: doc.default})})
    import(`../assets/docs/desafios-solucoes-${this.context.activeClass.version}.pdf`).then(doc => {this.setState({desafiosSolucoesPdf: doc.default})})
    if (this.context.activeClass.version === "cuf-saude"){
      import(`../assets/docs/manual-gamemaster-${this.context.activeClass.version}.pdf`).then(doc => {this.setState({manualGamemasterPdf: doc.default})})
      import(`../assets/docs/formato-classico-${this.context.activeClass.version}.pdf`).then(doc => {this.setState({formatoClassicoPdf: doc.default})})
      import(`../assets/docs/formato-remoto-${this.context.activeClass.version}.pdf`).then(doc => {this.setState({formatoRemotoPdf: doc.default})})
      import(`../assets/docs/formato-contra-relogio-${this.context.activeClass.version}.pdf`).then(doc => {this.setState({formatoContraRelogioPdf: doc.default})})
    } else {
      import(`../assets/docs/manual-gamemaster.pdf`).then(doc => {this.setState({manualGamemasterPdf: doc.default})})
      import(`../assets/docs/formato-classico.pdf`).then(doc => {this.setState({formatoClassicoPdf: doc.default})})
      import(`../assets/docs/formato-remoto.pdf`).then(doc => {this.setState({formatoRemotoPdf: doc.default})})
      import(`../assets/docs/formato-contra-relogio.pdf`).then(doc => {this.setState({formatoContraRelogioPdf: doc.default})})
    }
  }

  render() {
    let crewGameURL = `${window.location.origin}/turmas/${this.context.activeClass.url}`;

    return(
      <div className="o-dashboard">
        <TopBar
          teacherName={this.context.teacher.displayName}
          activeClassName={this.context.activeClass.name}
        />
        <section className="o-dashboard__section">
          <div className="o-dashboard__container">
            <div className="u-mb-6x" data-flout="row-- align-start--">
              <button className="c-button" onClick={(e) => this.context.classLogout()}>
                <img src={returnIcon} alt="professor" height="36px" className="u-pv"/>
                <span className="u-ml">mudar de turma</span>
              </button>
            </div>

            <div className="o-grid u-mb-6x" data-flout="row-- align-start-- justity-around--">
              <div className="o-grid__col u-12/12  u-6/12@sm u-mb">
                <h1 className="o-dashboard__title">
                  Bem-vind@ Gamemaster,
                </h1>
                <p className="u-mb-2x">
                  Se está a ler esta mensagem é porque tem como missão <span className="u-tw-700 u-color-blue-dark">Formar Heróis.</span><br/>
                  Damos-lhe as boas vindas à <span className="u-tw-700 u-color-orange-base u-ts-2">Liga de Professores Gamemasters</span>,<br/>um movimento nacional, onde irá
                  encontrar outros professores que, à sua semelhança, têm como símbolo de superação, a <span className="u-tw-700 u-color-blue-dark">superação dos seus alunos</span>.
                </p>
                <p className="u-mb-2x">
                  O seu percurso como Gamemaster começou - <span className="u-tw-700 u-color-blue-dark">A caixa foi aberta!</span><br/>
                  A cada missão poderá encontrar nela tudo o que precisa para transportar os seus alunos para universos fantásticos, onde os ajudará a encontrar as suas verdadeiras motivações, a descobrir forças que não sabiam que tinham e a traçar, passo a passo, os seus caminhos como heróis da sua própria história.
                </p>
                <p className="u-mb-2x">
                  Esta será a sua Jornada Épica! Conheça a sua 1.ª missão!
                </p>
              </div>
              <div className="o-grid__col u-10/12 u-6/12@sm u-mb">
                <div className="c-magic-box">
                  <img width="100%" src={spotBoxImg} alt="spotgames"/>
                  <img width="100%" src={bubblesLayer1Img} alt="spotgames" className="c-magic-box__bubbles c-magic-box__bubbles--slow"/>
                  <img width="100%" src={bubblesLayer2Img} alt="spotgames" className="c-magic-box__bubbles c-magic-box__bubbles--fast"/>
                </div>
              </div>
            </div>

            <div className="o-grid u-mb-6x" data-flout="row-- align-center--">
              <div className="o-grid__col u-12/12">
                <p className="u-mb-2x">
                  Em tempos, houve pessoas comuns que fizeram coisas extraordinárias.<br/>
                  As suas histórias foram contadas, recontadas, escritas, estudadas. Tornaram-se lendas!<br/>
                  Hoje estão por descobrir os heróis de carne e osso, como houve em tempos!
                </p>
              </div>
            </div>

            <div className="o-grid u-mb-6x" data-flout="row-- align-center-- justity-around--">
              <div className="o-grid__col u-12/12  u-6/12@sm u-mb">
                <h2 className="o-dashboard__title">
                  Esta é a sua 1ª missão!
                </h2>
                <p className="u-mb-2x">
                  <b>Formar heróis para que novas histórias inspiradoras sejam escritas</b> e, pouco a pouco, o mundo possa ser uma casa melhor para todos.
                </p>
                <p className="u-mb-2x">
                  Graças às suas capacidades de Gamemaster, depois desta aventura:
                </p>
                <ul>
                  <li>Os seus heróis terão descoberto novas paixões, causas e motivações;</li>
                  <li>Estarão mais preparados para as suas próximas aventuras;</li>
                  <li>Terão contribuído para ajudar a resolver os problemas da sua comunidade.</li>
                </ul>
              </div>
              <div className="o-grid__col u-10/12 u-6/12@sm u-mb" data-flitem="first@sm--">
                <img width="100%" src={gamemastersImg} alt="gamemasters" />
              </div>
            </div>

            <div className="o-grid u-mb-6x u-mb-10x@mdl" data-flout="row-- align-center-- justity-around--">
              <div className="o-grid__col u-12/12  u-6/12@sm u-mb-2x u-mb@mdl">
                <h2 className="o-dashboard__title">
                  Como?
                </h2>
                <p>
                  Cada aluno receberá o seu livro de aventuras em formato de Banda Desenhada, no qual será o herói e protagonista.<br/>Ao longo de seis aventuras, o herói será desafiado, página a página, a superar-se e a reescrever o curso da história!
                </p>
              </div>
              <div className="o-grid__col u-12/12 u-6/12@sm u-mb">
                <img width="100%" src={bdExampleImg} alt="desafio BD" />
              </div>
            </div>

            <div className="o-grid u-text-c" data-flout="row--">
              <div className="o-grid__col u-12/12">
                <p className="u-mb-2x u-tw-600 u-text-l">Ao longo da história, os heróis são desafiados a treinar as seguintes competências essenciais:</p>
              </div>
              <div className="o-grid__col u-12/12 u-6/12@sm u-4/12@mdl u-mb-6x u-mb-0@mdl" data-flout="col-- align-center--">
                <div className="o-dashboard__badge u-mb-2x">
                  <img src={descobertaIcon}  className="o-dashboard__badge__img" alt="descoberta"/>
                </div>
                <p className='u-tw-600 u-m'>Descoberta</p>
                <p>Os alunos pesquisam e auscultam outros para saber mais sobre cada tema</p>
              </div>
              <div className="o-grid__col u-12/12 u-6/12@sm u-4/12@mdl u-mb-6x u-mb-0@mdl" data-flout="col-- align-center--">
                <div className="o-dashboard__badge u-mb-2x">
                  <img src={acaoIcon}   className="o-dashboard__badge__img" alt="ação"/>
                </div>
                <p className='u-tw-600 u-m'>Acção</p>
                <p>Os alunos realizam ações simples com impacto na comunidade</p>
              </div>
              <div className="o-grid__col u-12/12 u-6/12@sm u-4/12@mdl" data-flout="col-- align-center--">
                <div className="o-dashboard__badge u-mb-2x">
                  <img src={mobilizacaoIcon}   className="o-dashboard__badge__img" alt="mobilização"/>
                </div>
                <p className='u-tw-600 u-m'>Mobilização</p>
                <p>Os alunos influenciam a comunidade a realizar ações, maximizando o seu impacto</p>
              </div>
            </div>
          </div>
        </section>

        <section className="o-dashboard__section u-bg-color-paper-base">
          <div className="o-dashboard__container">
            <h2 className="o-dashboard__title">
              Tutoriais e materiais de apoio para Professores Gamemasters
            </h2>
            <div className="o-grid" data-flout="row--">
              <div className="o-grid__col u-12/12" data-flout="align-start--">
                <Link to={this.state.manualGamemasterPdf} target="_blank" className="u-mb-2x u-mb-0@sm u-mr-3x">
                  <button
                    className="c-button c-button--big"
                  >Manual do Gamemaster</button>
                </Link>
                <Link to={this.state.matrizPedagogicaPdf} target="_blank" className="u-mb-2x u-mb-0@sm u-mr-3x">
                  <button
                    className="c-button c-button--big"
                  >Matriz Pedagógica</button>
                </Link>
                <Link to={this.state.desafiosSolucoesPdf} target="_blank" className="u-mb-2x u-mb-0@sm u-mr-3x">
                  <button
                    className="c-button c-button--big"
                  >Desafios & Soluções</button>
                </Link>
                <Link to={availableSoonPdf} target="_blank" className="u-mb-2x u-mr-3x u-hidden">
                  <button
                    className="c-button c-button--big"
                  >O que os Gamemasters perguntam</button>
                </Link>
              </div>
              <div className="o-grid__col u-12/12 u-4/12@mdl u-mb-4x">
                <div className="c-video">
                  <iframe
                    className="c-video__iframe"
                    title="video-tutorial"
                    allowFullScreen="allowfullscreen"
                    mozallowfullscreen="mozallowfullscreen"
                    msallowfullscreen="msallowfullscreen"
                    oallowfullscreen="oallowfullscreen"
                    webkitallowfullscreen="webkitallowfullscreen"
                    src={this.state.video1}
                  />
                </div>
                <p className="u-mt u-ts-4">Tutorial 1 | Registo de Professores</p>
              </div>
              <div className="o-grid__col u-12/12 u-4/12@mdl u-mb-4x">
                <div className="c-video">
                  <iframe
                    className="c-video__iframe"
                    title="video-tutorial"
                    allowFullScreen="allowfullscreen"
                    mozallowfullscreen="mozallowfullscreen"
                    msallowfullscreen="msallowfullscreen"
                    oallowfullscreen="oallowfullscreen"
                    webkitallowfullscreen="webkitallowfullscreen"
                    src={this.state.video2}
                  />
                </div>
                <p className="u-mt u-ts-4">Tutorial 2 | Lançamento do jogo</p>
              </div>
              <div className="o-grid__col u-12/12 u-4/12@mdl u-mb-4x">
                <div className="c-video">
                  <iframe
                    className="c-video__iframe"
                    title="video-tutorial"
                    allowFullScreen="allowfullscreen"
                    mozallowfullscreen="mozallowfullscreen"
                    msallowfullscreen="msallowfullscreen"
                    oallowfullscreen="oallowfullscreen"
                    webkitallowfullscreen="webkitallowfullscreen"
                    src={this.state.video3}
                  />
                </div>
                <p className="u-mt u-ts-4">Tutorial 3 | Missões de Turma</p>
              </div>
              <div className="o-grid__col u-12/12 u-4/12@mdl">
                <div className="c-video">
                  <iframe
                    className="c-video__iframe"
                    title="video-tutorial"
                    allowFullScreen="allowfullscreen"
                    mozallowfullscreen="mozallowfullscreen"
                    msallowfullscreen="msallowfullscreen"
                    oallowfullscreen="oallowfullscreen"
                    webkitallowfullscreen="webkitallowfullscreen"
                    src={this.state.video4}
                  />
                </div>
                <p className="u-mt u-ts-4">Tutorial 4 | Entrar como aluno</p>
              </div>
              <div className="o-grid__col u-12/12 u-4/12@mdl">
                <div className="c-video">
                  <iframe
                    className="c-video__iframe"
                    title="video-tutorial"
                    allowFullScreen="allowfullscreen"
                    mozallowfullscreen="mozallowfullscreen"
                    msallowfullscreen="msallowfullscreen"
                    oallowfullscreen="oallowfullscreen"
                    webkitallowfullscreen="webkitallowfullscreen"
                    src={this.state.video5}
                  />
                </div>
                <p className="u-mt u-ts-4">Tutorial 5 | Consultar respostas</p>
              </div>
              <div className="o-grid__col u-12/12 u-4/12@mdl">
                <div className="c-video">
                  <iframe
                    className="c-video__iframe"
                    title="video-tutorial"
                    allowFullScreen="allowfullscreen"
                    mozallowfullscreen="mozallowfullscreen"
                    msallowfullscreen="msallowfullscreen"
                    oallowfullscreen="oallowfullscreen"
                    webkitallowfullscreen="webkitallowfullscreen"
                    src={this.state.video6}
                  />
                </div>
                <p className="u-mt u-ts-4">Tutorial 6 | Dicas para os Professores Gamemasters</p>
              </div>
            </div>
          </div>
        </section>

        <section className="o-dashboard__section">
          <div className="o-dashboard__container">
            <h2 className="o-dashboard__title">
              Consulte as várias opções para jogar com os seus heróis:
            </h2>
            <div className="o-grid u-text-c" data-flout="row--">
              <div className="o-grid__col u-12/12 u-6/12@sm u-4/12@mdl u-mb-10x u-mb-0@mdl" data-flout="col-- align-center--">
                <div className="o-dashboard__badge u-mb-2x">
                  <p className="o-dashboard__badge__text">CLÁSSICO</p>
                </div>
                <p className="u-mb-2x">Os heróis revelam-se em sala-de-aula!</p>
                <Link to={this.state.formatoClassicoPdf} target="_blank">
                  <button className="c-button c-button--big">+ info</button>
                </Link>
              </div>
              <div className="o-grid__col u-12/12 u-6/12@sm u-4/12@mdl u-mb-10x u-mb-0@mdl" data-flout="col-- align-center--">
                <div className="o-dashboard__badge u-mb-2x">
                  <p className="o-dashboard__badge__text">REMOTO</p>
                </div>
                <p className="u-mb-2x"> A solução ideal para formar heróis à distância.</p>
                <Link to={this.state.formatoRemotoPdf} target="_blank">
                  <button className="c-button c-button--big">+ info</button>
                </Link>
              </div>
              <div className="o-grid__col u-12/12 u-6/12@sm u-4/12@mdl u-mb-0@mdl" data-flout="col-- align-center--">
                <div className="o-dashboard__badge u-mb-2x">
                  <p className="o-dashboard__badge__text">CONTRA-RELÓGIO</p>
                </div>
                <p className="u-mb-2x">Tempo de aventura limitado, experiência mais intensa!</p>
                <Link to={this.state.formatoContraRelogioPdf} target="_blank">
                  <button className="c-button c-button--big">+ info</button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="o-dashboard__section u-bg-color-paper-base">
          <div className="o-dashboard__container">
            <h2 className="o-dashboard__title">
              Os seus Heróis
            </h2>
            <div className="o-grid" data-flout="row--">
              <div className="o-grid__col u-12/12 u-4/12@mdl u-pr" data-flout="col-- justity-around--justify-start@mdl--" data-flitem="last@mdl--">
                <div className="u-mb-2x" data-flout="justify-between-- align-center--" data-flitem="align-stretch--">
                  <div  data-flout="row-- align-center--" data-flitem="grow--">
                    <div className="o-dashboard__bam">
                      <p className='o-dashboard__bam__text'>{this.context.activeClass.name}</p>
                    </div>
                  </div>
                  {this.state.closingClass &&
                    <p className="c-loader__text">A terminar...</p>
                  }
                  {this.context.activeClass.status === CLASS_STATUS.PLAYING &&
                    <TerminateModal
                      triggerButton="Terminar Jogo"
                      title="Terminar Jogo"
                      description="Atenção: esta ação é irreversível! Se terminar seu jogo com esta turma os seus heróis ficarão impedidos de realizar novos desafios. Caso queira apenas sair da sua página de professor, deverá clicar em 'sair' no topo da página"
                      confirmQuestion= "Tem a certeza que quer terminar?"
                      cancelButton="Não, voltar à página"
                      confirmButton="Sim, terminar o jogo"
                      confirmAction={this.terminateGame}
                    />
                  }
                </div>
                {((this.context.activeClass.status === CLASS_STATUS.PLAYING) || (this.context.activeClass.status === CLASS_STATUS.CLOSED && !this.state.closingClass)) &&
                  <div data-flitem="align-stretch--">
                    <p className="u-mb-2x">
                      {this.context.activeClass.status === CLASS_STATUS.PLAYING ?
                        "Link Inscrição Alunos"
                        :
                        "Terminou o seu jogo com esta turma. Os seus heróis já não poderão realizar novos desafios, mas podem continuar a consultar a sua Banda Desenhada, acedendo ao link:"
                      }
                    </p>
                    <form>
                      <textarea
                        className="u-sr-only"
                        ref={(registerurl) => this.registerURL= registerurl}
                        value={crewGameURL}
                        readOnly
                      />
                    </form>
                    <div data-flout="row-- justify-end--">
                      <a className="o-dashboard__text-link" href={crewGameURL} target="_blank" rel="noreferrer">
                        {crewGameURL}
                      </a>
                    </div>
                   {
                   document.queryCommandSupported('copy') &&
                      <div data-flout="row-- justify-end--">
                        <button
                        className="c-button u-mb"
                        onClick={this.copyToClipboard}
                        >
                          copiar link
                        </button>
                      </div>
                    }
                    <div data-flout="row-- justify-end--">{this.state.copySuccess}</div>
                  </div>
                }
              </div>
              <div className="o-grid__col u-12/12 u-8/12@mdl u-mb-6x u-mb-0@mdl">
                <div className="o-table-wrapper">
                  <table className="o-table">
                    <thead className="o-table__head">
                      <tr className="o-table__headings">
                        <th className="o-table__field">Nº ALUNO</th>
                        <th className="o-table__field u-text-l">NOME ALUNO</th>
                        <th className="o-table__field">TUTORIAL</th>
                        <th className="o-table__field">NÍVEL 3</th>
                        <th className="o-table__field">Nº AVENTURAS</th>
                        <th className="o-table__field">Nº DESAFIOS</th>
                        <th className="o-table__field">RESPOSTAS</th>
                      </tr>
                    </thead>
                    <tbody className="o-table__body">
                      { this.context.activeClass.students.length ?
                        this.context.activeClass.students.sort((a, b) => a.studentNo - b.studentNo).map((student, key) =>
                          <tr key={key} className="o-table__row">
                            <td className="o-table__cell">
                              <label className="o-table__cell__label">nº aluno:</label>
                              {student.studentNo}
                            </td>
                            <td className="o-table__cell u-text-l">
                              <div className="o-table__cell-truncate">
                                <label className="o-table__cell__label">nome:</label>
                                {this.shortenStudentName(student.name)}
                              </div>
                            </td>
                            <td className="o-table__cell">
                              <label className="o-table__cell__label">tutorial:</label>
                              {student.tutorialSolved ? "✓" : ""}
                            </td>
                            <td className="o-table__cell">
                              <label className="o-table__cell__label">nível 3:</label>
                              {student.lastLevelSolved ? "✓" : ""}
                            </td>
                            <td className="o-table__cell">
                              <label className="o-table__cell__label">ranking:</label>
                              {student.solvedProblems}
                            </td>
                            <td className="o-table__cell">
                              <label className="o-table__cell__label">nº desafios</label>
                              {student.solvedChallenges}
                            </td>
                            <td className="o-table__cell">
                              <Link
                                to={`/respostas/${student.studentId}`}
                                target="_blank"
                              >
                                <button className="c-button">ver respostas</button>
                              </Link>
                            </td>
                          </tr>
                        )
                        :
                        (
                          <tr className="o-table__row">
                            <td className="o-table__cell u-pv-10x" colSpan={6}>
                            Ainda não há alunos registados.
                            </td>
                          </tr>
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="o-dashboard__section">
          <div className="o-dashboard__container">
            <h2 className="o-dashboard__title">
              Missões de turma
            </h2>
            <Missions
              classId={this.context.classId}
              gameStatus={this.context.activeClass.status}
              students={this.context.activeClass.students}
              missions={this.context.activeClass.missions}
            />
          </div>
        </section>
      </div>
    )
  }
}


export default TeacherDashboard
