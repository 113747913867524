import React from 'react';

import Title from '../components/game/title.js';
import Input from '../components/game/appInput.js';
import AppButtonIcon from '../components/game/appButtonIcon.js';
import AvatarFull from '../components/avatar/full.js';

import { StudentDataContext } from '../providers/StudentDataProvider.js';
import { auth, createStudent } from '../middlewares/firebase';
import { parseGameVersion } from "../utils/funcs"

class AvatarSelection extends React.Component {
  static contextType = StudentDataContext;

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      confirmDisabled: true,
      avatarPrev: 6,
      avatarTheme: 1,
      avatarNext: 2,
      loading: false
    }
  }

  changeTheme(decrease) {
    var currTheme = [this.state.avatarPrev, this.state.avatarTheme, this.state.avatarNext]

    // Increase or decrease theme parameter
    const newTheme = currTheme.map(function(value) {
      if(decrease) value --
      else value ++

      // Loop parameters between 1 and 6
      if(value > 6) value = 1
      else if(value < 1) value = 6

      return value
    })

    this.setState({
      avatarPrev: newTheme[0],
      avatarTheme: newTheme[1],
      avatarNext: newTheme[2]
    })
  }

  updateName(event){
    // Update avatar name state variable
    this.setState({ name: event.target.value})

    // Unlock confirm button if name input filled
    if(event.target.value.length > 0) {
      this.setState({ confirmDisabled: false})
    } else {
      this.setState({ confirmDisabled: true})
    }
  }

  registerStudent() {
    this.setState({
      loading: true
    });

    createStudent(auth.currentUser.uid, {
      studentNo: this.context.claims.studentNo,
      classId: this.context.claims.classId,
      name: this.state.name,
      avatar: this.state.avatarTheme,
      version: this.context.activeClass.version
    }, this.context.challenges );
  }

  render() {
    return (
      <div className="o-app__bg-dots">
        <div className="o-app__frame">
          <section className="o-view">
            <div className="o-app__container o-app__container--nowrap" data-flout="justify-center--">
              <Title
                content="Escolhe quem serás nesta grande aventura!"
                modifiers="u-mv-2x c-title--sm u-pr-3x u-pb-3x"
              />
            </div>
            <div data-flout="row-- justity-around-- expand-- align-center--" className="c-avatar-selection u-mb-2x">
              <div data-flout="col-- justify-end--">
                <AvatarFull
                  game={parseGameVersion(this.context.activeClass.version)}
                  theme={this.state.avatarPrev}
                  modifiers="u-mb-4x"
                />
                <AppButtonIcon
                  name="Avatar anterior"
                  icon="left"
                  onClick={() => this.changeTheme(true)}
                />
              </div>
              <div data-flout="row-- align-center--" className="c-avatar-full-wrapper">
                <AvatarFull
                  game={parseGameVersion(this.context.activeClass.version)}
                  theme={this.state.avatarTheme}
                  modifiers="c-avatar-full--selected"
                />
              </div>
              <div data-flout="col-- justify-end--">
                <AvatarFull
                  game={parseGameVersion(this.context.activeClass.version)}
                  theme={this.state.avatarNext}
                  modifiers="u-mb-4x"
                />
                <AppButtonIcon
                  name="Próximo avatar"
                  icon="right"
                  onClick={() => this.changeTheme(false)}
                />
              </div>
            </div>
            <div className="o-app__container u-pb-2x" data-flout="justify-end-- expand--">
              <div data-flout="justify-start-- expand--">
                <Title
                  content="Como te queres chamar?"
                  modifiers="c-title--sm u-mb"
                />
                <Input
                  id="crew-name"
                  inverted={true}
                  placeholder="O teu nome"
                  modifiers="u-mb-2x"
                  onChange={(e) => this.updateName(e)}
                />
              </div>
              <button
                className="c-button"
                disabled={this.state.confirmDisabled}
                onClick={() => this.registerStudent()}
              >
                Next
              </button>
            </div>
          </section>
        </div>
      </div>
    )
  }
}

export default AvatarSelection
