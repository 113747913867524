import * as React from "react"

import LogoPromoted from '../../assets/images/logos/promoted.png';
import LogoClients from '../../assets/images/logos/clients.png';
import LogoCofinanced from '../../assets/images/logos/cofinanced.png';
import LogoSpotgames from '../../assets/images/logos/spotgames.png';
import LogoCUF from '../../assets/images/logos/cuf.png';

class AppFooter extends React.Component {

  renderNote(note){
    if(note) return (
      <p className="o-footer__note">{note}</p>
    )
  }

  renderLogos(isTeacher, isCuf) {
    if (isTeacher) {
      return (
        <div data-flout="justify-center--">
          <img className="o-footer__logo" src={LogoPromoted} alt="Promovido por: SpotGames" />
          <img className="o-footer__logo" src={LogoClients} alt="Clientes: CM Lousã / Vila Nova de Poiares / Torres Vedras / Oeiras / CUF" />
          <img className="o-footer__logo" src={LogoCofinanced} alt="Co-financiado por: Portugal Inovação Social / Portugal 2020" />
        </div>
      )
    } else {
      return (
        <div data-flout="justify-center--">
          <img className="o-footer__logo" src={LogoSpotgames} alt="SpotGames" />
          {isCuf && <img className="o-footer__logo" src={LogoCUF} alt="CUF" />}
        </div>

      )
    }
  }

  render() {
    return (
      <footer className="o-footer">
        {this.renderNote(this.props.note)}
        {this.renderLogos(this.props.isTeacher, this.props.isCuf)}
      </footer>
    )
  }
}

export default AppFooter
