import React from 'react'
import { Link } from "react-router-dom"
import TabNavigation from "../components/level/tabNavigation.js"
import Panel from "../components/level/lastChapterPanel.js"
import { PageContext } from "../providers/PageProvider.js"
import { StudentDataContext } from "../providers/StudentDataProvider.js"
import { parseGameVersion } from "../utils/funcs"

class LastChapter extends React.Component {
  static contextType = PageContext;
  componentDidMount() {
    this.context.setPage(null,null);
  }
  isGameCompleted(levels) {
    let completed = false;
    levels[1].problems.every(function (problem, index) {
      if ( problem.completed ) {
        return completed = true;
      }
    });
    return completed;
  }
  render() {
    return(
      <StudentDataContext.Consumer>
        {({ activeClass, student }) => (
          <div className="o-app__bg-wood">
            <div className="o-app__frame o-app__frame--lg">
              <section className="o-view">
                <TabNavigation/>
                <div className="o-bd">
                {[...Array(3),].map((value, index) => (
                  <Panel
                    game={parseGameVersion(activeClass.version)}
                    completed={this.isGameCompleted(student.gameState.levels)}
                    avatarTheme={student.avatar}
                    avatarLevel={student.gameState.avatar}
                    panel={index + 1}
                    key={index}
                  />
                ))}
                <Link to='/perfil'>
                  <button
                    className="c-button"
                  >
                    Voltar
                  </button>
                </Link>
                </div>
              </section>
            </div>
          </div>
        )}
      </StudentDataContext.Consumer>
    )
  }
}


export default LastChapter
