import * as React from "react"
import Step from "./step.js"
import Button from "./button.js"
import Stepper from "./stepper.js"
import CloseIcon from "../../assets/images/icon-close.svg"
import Loader from "../game/loader.js"

class ChallengeModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      answers: {},
      submitting: false
    }

    this.modalBody = React.createRef();

    this.handleAnswerUpdate = this.handleAnswerUpdate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.canMoveForward = this.canMoveForward.bind(this);
    this.getExistingAnswer = this.getExistingAnswer.bind(this);
  }

  componentDidUpdate(prevProps){
    if(prevProps.challenge.id !== this.props.challenge.id){
      this.setState({
        submitting: false,
        answers: {}
      })
    }
    if(prevProps.activeStep !== this.props.activeStep){
      // Scroll modal body to top on step change
      this.modalBody.current.scrollTo(0, 0)
    }
  }

  handleAnswerUpdate(event) {
    const activeStepIndex = this.props.activeStep;
    let activeStep = this.props.steps[activeStepIndex];
    let answers = this.state.answers;

    // Update the answer object
    if(!answers[activeStepIndex]){
      answers[activeStepIndex] = {
        type: activeStep.input.type,
        label: activeStep.input.label,
        extraPoints: activeStep.input.extraPoints
      }
    }

    if(activeStep.input.type === "file"){
      let files = event.target.files;

      if(files.length === 0){
        answers[activeStepIndex].file = null;
        answers[activeStepIndex].value = "";
      } else {
        answers[activeStepIndex].file = files[0];
        answers[activeStepIndex].value = files[0].name;
      }

    } else {
      answers[activeStepIndex].value = event.target.value;
    }

    this.setState({
      answers
    });
  }

  handleSubmit(event) {
    const activeStepIndex = this.props.activeStep;
    const numSteps = this.props.steps.length;

    this.setState({
      submitting: true
    });

    this.props.onAnswerSubmit(this.state.answers)
      .then(() => {
        this.setState({
          submitting: false
        });
        this.props.updateLastProblem();
        if(activeStepIndex === numSteps - 1){
          this.props.closeChallenge();
        } else {
          this.props.nextStep();
        }
      });

    event.preventDefault();
  }

  getExistingAnswer(){
    const activeStepIndex = this.props.activeStep;
    const activeStep = this.props.steps[activeStepIndex];

    if(activeStep.type !== "question"){
      return null
    }

    if(!this.state.answers[activeStepIndex]){
      return "";
    }

    if(!this.state.answers[activeStepIndex].value){
      return "";
    }

    return this.state.answers[activeStepIndex].value;
  }

  canMoveForward(){
    const activeStepIndex = this.props.activeStep;
    const activeStep = this.props.steps[activeStepIndex];

    if(this.props.challenge.completed){
      return true;
    }

    // If we're submitting the answer, wait
    if(this.state.submitting){
      return false;
    }

    if(activeStep.type !== "question"){
      return true;
    }

    if(!this.state.answers[activeStepIndex]){
      return false;
    }

    if(!this.state.answers[activeStepIndex].value){
      return false
    }

    return true;
  }

  render() {
    const challenge = this.props.challenge
    const numSteps = this.props.steps.length
    const activeStepIndex = this.props.activeStep

    if(numSteps === 0){
      return <div className="o-modal" open={false} />
    }

    const activeStep = this.props.steps[activeStepIndex]
    const previousStep = this.props.steps[activeStepIndex - 1]

    const currentAnswer = this.getExistingAnswer();

    return(
      <div className="o-challenge">
        <div className="o-modal" open={this.props.isOpen}>
          {activeStep.type !== "extra" && !this.state.submitting &&
            <div
              className="o-modal__close"
              onClick={this.props.closeChallenge}>
              <img src={CloseIcon} alt="close"/>
            </div>
          }
          <div className="o-modal__header o-modal__blend--bottom">
            <h1 className="o-challenge__title u-mb-2x">
              {challenge.code} {challenge.title}
            </h1>
            {/* stepper progress */}
            <Stepper
              numSteps={numSteps}
              currentStep={activeStepIndex}
            />
          </div>
          {/* step content */}
          <div className="o-modal__body o-modal__body--scroll" ref={this.modalBody}>
            {this.state.submitting ?
              <Loader submitting/>
              :
              <Step
                key={activeStep.order}
                step={activeStep}
                onAnswerUpdate={this.handleAnswerUpdate}
                value={this.props.challenge.completed ? this.props.activeStepAnswer : currentAnswer}
                challengeCompleted={this.props.challenge.completed}
              />
            }
            {/* step navigation */}
            <div className="o-modal__actions">
              <div>
                {/* allow go back except from extra info to submit phase */}
                { activeStepIndex > 0 && previousStep.type  !== "submit" &&
                  <Button
                    name="anterior"
                    onClick={this.props.previousStep}
                    disabled={this.props.challenge.completed ? false : this.state.submitting}
                  />
                }
              </div>
              <div>
                {/* move to extra-info if submit not the last step */}
                {/* also handle submitting the answers if we're in the submit step*/}
                {activeStepIndex < numSteps - 1 &&
                  <Button
                    name={activeStep.type === "submit" ?  (this.state.submitting ? "a submeter" : "submeter") : "próximo"}
                    onClick={activeStep.type === "submit" ? this.handleSubmit : this.props.nextStep}
                    disabled={!this.canMoveForward()}
                  />
                }
                {/* close modal on last step */}
                {activeStepIndex === numSteps - 1  &&
                  <Button
                    name={this.props.challenge.completed ? "fechar" : (activeStep.type === "submit" ?  "submeter" : "fechar")}
                    onClick={this.props.challenge.completed ? this.props.closeChallenge : (activeStep.type === "submit" ? this.handleSubmit : this.props.closeChallenge)}
                  />
                }
              </div>
            </div>
          </div>
          <div className="o-modal__footer o-modal__blend--top" />
        </div>
      </div>
    )
  }
}

export default ChallengeModal
