import * as React from "react"
import ReactMarkdown from "react-markdown"

class Text extends React.Component {
  render() {
    return (
      <ReactMarkdown className="o-challenge__text" linkTarget="_blank" children={this.props.text} />
    )
  }
}

export default Text