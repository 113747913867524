import React from 'react';
import Input from '../components/game/appInput.js';
import Title from '../components/game/title.js';
import spotBoxImg from "../assets/images/caixa.png"
import bubblesLayer1Img from "../assets/images/bolhas-layer-1.png"
import bubblesLayer2Img from "../assets/images/bolhas-layer-2.png"
import AppFooter from '../components/game/appFooter.js';
import { teacherSignIn } from '../middlewares/firebase';

class TeacherLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({email: event.target.value});
  }

  handleSubmit(event) {
    alert('An email will be sent to the following email: ' + this.state.email);

    teacherSignIn(this.state.email);

    event.preventDefault();
  }

  handleEnterKey = (event) => {
    // Login when Enter key press
    if(event.keyCode === 13) {
      this.handleSubmit(event)
    }
  }

  render() {
    return (
      <div className="o-app__bg-dots-light">
        <div className="o-app__login">
          <div className="o-app__frame">
            <section className="o-view">
              <div data-flout="expand-- justify-center--">
                <div className="o-app__container" data-flout="justify-center--">
                  <h1 className="c-text-banner u-mt u-mb">
                    Login Professor Gamemaster
                  </h1>
                </div>
              </div>
              <div data-flout="expand-- justify-center--">
                <div className="o-app__container" data-flout="justify-start-- expand--">
                  <div className="c-magic-box u-mb-2x u-mb-6x@sm">
                    <img width="100%" src={spotBoxImg} alt="Login no SPOT Cidadania"/>  
                    <img width="100%" src={bubblesLayer1Img} alt="spotgames" className="c-magic-box__bubbles c-magic-box__bubbles--slow"/>  
                    <img width="100%" src={bubblesLayer2Img} alt="spotgames" className="c-magic-box__bubbles c-magic-box__bubbles--fast"/>   
                  </div>
                  <Title
                    content="Escreva aqui o seu email e entre na aventura!"
                    modifiers="c-title--sm u-mb u-pl-2x u-pr-3x"
                  />
                  <Input
                    id="crew-name"
                    inverted={true}
                    value={this.state.email}
                    onChange={this.handleChange}
                    onKeyDown={this.handleEnterKey}
                    readOnly={false}
                    placeholder="Email para login"
                  />
                </div>
                <div className="o-app__container c-input-group u-mv-2x" data-flout="justify-end-- expand--">
                  <button
                    className="c-button"
                    onClick={this.handleSubmit}
                  >
                    Login
                  </button>
                </div>
              </div>
              <AppFooter isTeacher/>
            </section>
          </div>
        </div>
      </div>
    )
  }
}

export default TeacherLogin
