import * as React from "react"
import Item from "./item.js"
import { Link } from "react-router-dom"
import { PageContext } from '../../providers/PageProvider.js'

function Collection(props) {
  return (
    <div className="c-collection">
      <div className="c-collection__title c-title">
        {props.name}
      </div>
      <PageContext.Consumer>
        {({setPage}) => (
          <div className="c-collection__items">
            {props.levels.map( (level, index) =>
              level.problems.map( (problem, index) =>
                problem.nChallengesCompleted > 0 ?
                  <Link 
                    key={index}
                    className="c-item-collection c-item-collection--clickable"
                    onClick={(e) => setPage(level.order,problem.name)}
                    to={{
                    pathname: '/problema',
                    state: {
                      level: level.order,
                      problem: problem.name.toString(),
                      onBackRedirectTo: '/perfil'
                    }
                  }}>
                    <Item
                      game = {props.game}
                      level={level.order}
                      problem={problem}
                      avatarTheme={props.avatarTheme}
                      completed={problem.completed}
                    />
                  </Link>
                  :
                  <div 
                    key={index}
                    className="c-item-collection"
                  >
                    <Item
                      game = {props.game}
                      level={level.order}
                      problem={problem}
                      avatarTheme={props.avatarTheme}
                      completed={problem.completed}
                    />
                  </div>
              )
            )}
          </div>
        )}
      </PageContext.Consumer>
    </div>
  )
}

export default Collection
