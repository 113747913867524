import React from "react"
import { Link } from "react-router-dom"
import TabNavigation from "../components/level/tabNavigation.js"
import BdPanel from "../components/level/bdPanel.js"
import PageNavigation from "../components/level/pageNavigation.js"
import { PageContext } from "../providers/PageProvider.js"
import { StudentDataContext } from "../providers/StudentDataProvider.js"
import { parseGameVersion } from "../utils/funcs"
import { ACTION_PANEL } from '../utils/constants'

class Level extends React.Component {
  static contextType = StudentDataContext;

  constructor(props) {
    super(props);
    this.pageBottom = React.createRef();
  }

  scrollToBottom(){
    // On Desktop, scroll to the bottom of the page if any challenge 
    // is just completed (including tutorial)
    if(this.props.location.state?.anyCompleted && window.innerWidth > 768) {
      setTimeout(() => {
        this.pageBottom.current.scrollIntoView({behavior: "smooth"})
      }, 500)
    }
  }

  componentDidMount() {
    this.scrollToBottom()
  }

  render() {
    const levels = this.context.student.gameState.levels
    return (
      <PageContext.Consumer>
        {({activeLevel, activeProblem}) => (
          <div className="o-app__bg-wood">
            <div className="o-app__frame o-app__frame--lg">
              <section className="o-view">
                <TabNavigation
                  justCompleted={this.props.location.state && this.props.location.state.justCompleted}
                />
                <div className="o-bd">
                  {[...Array(4)].map((el, index) =>
                    index+1 === ACTION_PANEL[activeLevel][activeProblem-1] && !levels[activeLevel].problems[activeProblem-1].completed ?
                     ( <div
                        key={'panel'+activeLevel+activeProblem+index}
                        className={`o-bd__panel o-bd__panel--${index+1} o-bd__panel--clickable ${ parseGameVersion(this.context.activeClass.version) === "cuf-saude" && "o-bd__panel--bordered"}`}
                      >
                        <Link to={{
                            pathname: '/problema',
                            state: {
                              level: activeLevel,
                              problem: activeProblem.toString()
                            }
                          }}
                        >
                         <BdPanel
                            game= {parseGameVersion(this.context.activeClass.version)}
                            level={activeLevel}
                            problem={activeProblem}
                            avatarTheme={this.context.student.avatar}
                            panel={index+1}
                            actionPanel={index+1}
                            completed={false}
                          />
                        </Link>
                      </div>
                      )
                      :
                      (<div
                        key={'panel'+activeLevel+activeProblem+index}
                        className={`o-bd__panel o-bd__panel--${index+1}`}
                      >
                       <BdPanel
                          game= {parseGameVersion(this.context.activeClass.version)}
                          level={activeLevel}
                          problem={activeProblem}
                          avatar={levels[activeLevel].problems[activeProblem-1].avatar}
                          avatarTheme={this.context.student.avatar}
                          panel={index+1}
                          actionPanel={ACTION_PANEL[activeLevel][activeProblem-1]}
                          completed={levels[activeLevel].problems[activeProblem-1].completed}
                        />
                      </div>)

                  )}
                  <PageNavigation
                    key={'nav-button'+activeLevel+activeProblem}
                    levels={levels}
                    activeLevel={parseInt(activeLevel)}
                    activeProblem={parseInt(activeProblem)}
                  />
                  <div ref={this.pageBottom} />
                </div>
              </section>
            </div>
          </div>
        )}
      </PageContext.Consumer>
    )
  }
}

export default Level
