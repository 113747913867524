import * as React from "react"

class SpiritMessage extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      spiritUrl: ""
    }
  }

  componentDidMount() {
    // Render icon described as prop
    import(`../../assets/images/${this.props.game}/spirit-${this.props.smiling ? 2 : 1}.svg`).then(image => {this.setState({spiritUrl: image.default})})
  }

  render() {
    return (
      <div className="c-spirit">
        <img
          src={this.state.spiritUrl}
          className="c-spirit__image"
          alt="Spirit"
        />
        <div className="c-spirit__balloon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 185.76 42.96"
            preserveAspectRatio="none"
          >
            <g>
              <path
                d="M61.05 42.58H19.47c-6.69-.1-7.24-2.07-8.59-6.85-.3-1.07-.65-2.28-1.1-3.63-1.35-4-2.06-4.53-2.58-4.53a.63.63 0 00-.59.43 8.17 8.17 0 01-1.85 1.9c-.25.21-.49.4-.7.6a4.81 4.81 0 01-3 1.37.87.87 0 01-.48-.09c-.15-.29.47-1.29 1-2.18A23.16 23.16 0 003 27a11.41 11.41 0 00.72-2.4 6.78 6.78 0 01.92-2.57 6.25 6.25 0 012.79-1.79 3.27 3.27 0 012.8 2l.72 1.83v-10c0-7.39 1.88-10.91 12.58-10.91l75.12.13 72.46.12c9.41 0 13.51 2.4 13.71 8 .09 2.38.52 14.64.26 22.3-.15 4.47-.3 8.7-13 8.7H157c-22.26.14-64.14.17-95.95.17z"
                fill="#babcbe"
              />
              <path
                d="M23.52 3.63l75.12.12 72.46.12c11.51 0 13.2 3.88 13.34 7.68.09 2.38.51 14.63.25 22.26-.15 4.48-.28 8.34-12.58 8.34H157c-22.42 0-64.16.05-96 .05-25 0-39 0-41.57-.06-6.41-.09-6.91-1.85-8.24-6.57-.3-1.07-.65-2.29-1.11-3.65C8.78 28 8 27.15 7.2 27.15a1 1 0 00-.9.58 7.76 7.76 0 01-1.78 1.83c-.25.21-.5.41-.71.61A4.42 4.42 0 011 31.44a12.06 12.06 0 01.93-1.69 27 27 0 001.39-2.55 12.6 12.6 0 00.75-2.48 6.6 6.6 0 01.85-2.43 5.63 5.63 0 012.5-1.62 2.94 2.94 0 012.44 1.8l1.45 3.71v-12c0-7.28 1.76-10.53 12.21-10.53m0-.75c-11.27 0-13 4-13 11.28v8a3.69 3.69 0 00-3.24-2.27 6.53 6.53 0 00-3 1.92c-1 1.27-.9 3.38-1.68 5-1.11 2.37-2.83 4.25-2.4 5 .09.17.38.27.8.27a5.22 5.22 0 003.28-1.47A12.17 12.17 0 007 28.08a.28.28 0 01.24-.18c.32 0 1 .63 2.22 4.27 2.5 7.39 1.53 10.61 10 10.73 2.94.1 19.93.1 41.59.1 43.33 0 105.37-.06 111.06-.06 12.57 0 13.16-4 13.33-9.06.24-6.93-.08-17.76-.25-22.32C185 5.87 181 3.12 171.1 3.12c-7.73 0-139.49-.24-147.58-.24z"
              />
              <path
                d="M61.05 39.58c-25 0-39 0-41.58-.06-6.59-.09-7.1-1.9-8.47-6.75-.3-1.07-.65-2.29-1.1-3.64-1.38-4.05-2.11-4.61-2.7-4.61a.77.77 0 00-.71.5 8 8 0 01-1.81 1.86c-.25.2-.49.4-.7.59A4.66 4.66 0 011 28.81a.85.85 0 01-.4-.06 7.47 7.47 0 011-2 22.43 22.43 0 001.41-2.52 11.52 11.52 0 00.73-2.43 6.68 6.68 0 01.9-2.52 5.94 5.94 0 012.67-1.72 3.16 3.16 0 012.7 2l1 2.42v-10.7C11.06 4 12.92.5 23.52.5l75.12.12 72.46.12c9.32 0 13.39 2.37 13.59 7.92.09 2.38.51 14.62.25 22.28-.15 4.61-.29 8.58-12.83 8.58H157c-22.26.03-64.14.06-95.95.06z"
                fill="#fff"
              />
              <path
                d="M23.52 1l75.11.12 72.47.12c11.29 0 13 3.76 13.09 7.44.09 2.37.51 14.62.25 22.25-.15 4.52-.27 8.09-12.33 8.09H61c-25 0-39 0-41.57-.06-6.22-.08-6.67-1.67-8-6.39-.3-1.08-.65-2.3-1.11-3.66C9 24.83 8.15 24 7.2 24a1.27 1.27 0 00-1.12.7 7.43 7.43 0 01-1.72 1.77c-.26.21-.5.41-.72.62a4.4 4.4 0 01-2.28 1.17c.19-.38.5-.88.75-1.28a27.37 27.37 0 001.44-2.58 12.43 12.43 0 00.76-2.53 6.54 6.54 0 01.81-2.33 5.44 5.44 0 012.33-1.51 2.69 2.69 0 012.18 1.63l1.93 5V11.28c0-6.21.83-10.28 12-10.28m0-1c-11.27 0-13 4-13 11.28v8A3.69 3.69 0 007.32 17a6.53 6.53 0 00-3 1.92c-1 1.27-.9 3.38-1.68 5C1.53 26.37-.19 28.25.24 29c.09.17.38.27.8.27a5.22 5.22 0 003.28-1.47A12.17 12.17 0 007 25.2a.28.28 0 01.2-.2c.32 0 1 .63 2.22 4.27C11.92 36.68 11 39.9 19.47 40c2.93 0 19.92.06 41.58.06 43.33 0 105.37-.06 111.06-.06 12.57 0 13.16-4 13.33-9.06.24-6.93-.08-17.76-.25-22.32C185 3 181 .24 171.1.24 163.37.24 31.61 0 23.52 0z"
                fill="#0b0b0b"
              />
            </g>
          </svg>
          <label>{this.props.text}</label>
        </div>
      </div>
    )
  }
}

export default SpiritMessage
