import * as React from "react"

import descobertaIcon from "../../assets/images/icon-descoberta.svg"
import accaoIcon from "../../assets/images/icon-accao.svg"
import mobilizacaoIcon from "../../assets/images/icon-mobilizacao.svg"
import influencerIcon from "../../assets/images/icon-influencer.svg"
import diaDiferenteIcon from "../../assets/images/icon-dia-diferente.svg"
import tempoAntenaIcon from "../../assets/images/icon-tempo-antena.svg"
import caixaFerramentasIcon from "../../assets/images/icon-caixa-ferramentas.svg"

function selectedCategory(category){
  if (category === "descoberta") {
    return descobertaIcon
  } else if (category === "accao") {
    return accaoIcon
  } else if (category === "mobilizacao") {
    return mobilizacaoIcon
  } else if (category === "influencer") {
    return influencerIcon
  } else if (category === "dia-diferente") {
    return diaDiferenteIcon
  } else if (category === "tempo-antena") {
    return tempoAntenaIcon
  } else if (category === "caixa-ferramentas") {
    return caixaFerramentasIcon
  } 
}

function CategoryIcon(props) {
  return (
    <img src={selectedCategory(props.category)} width="48px" alt={props.category}/>
  )
}

export default CategoryIcon