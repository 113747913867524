import { version } from "react";

export const findProblemStatusInLevel = (problemList, problemName) => {
  let problemMatch = null;

  problemList.forEach( (problem) => {
    if(problem.name === problemName)
      problemMatch = problem;
  });

  return problemMatch;
}

export const parseGameVersion = (versionWithAgeGroup) => {
  if(versionWithAgeGroup.endsWith('-2c') || versionWithAgeGroup.endsWith('-3c')){
    return versionWithAgeGroup.slice(0, -3);
  } else {
    return versionWithAgeGroup;
  }
}

export const parseChallengeType = (type) => {
  switch (type) {
    case "descoberta":
      return "Descoberta";
    case "accao":
      return "Ação";
    case "mobilizacao":
      return "Mobilização";
    case "influencer":
      return "Influencer";
    case "dia-diferente":
      return "Dia Diferente";
    case "tempo-antena":
      return "Tempo de Antena";
    case "caixa-ferramentas":
      return "Caixa de Ferramentas";
    default:
      return "";
  }
}
