import * as React from "react"

import Background from '../../assets/images/background-category-evolution.svg';

function selectedCategoryEvolution(type, level){
  switch (type) {
    case "Descoberta":
      switch (level) {
        case 6:
          return "descoberta_07"
        case 5:
          return "descoberta_06"
        case 4:
          return "descoberta_05"
        case 3:
          return "descoberta_04"
        case 2:
          return "descoberta_03"
        case 1:
          return "descoberta_02"
        default:
          return "descoberta_01"
      }
    case "Ação":
      switch (level) {
        case 6:
          return "acção_07"
        case 5:
          return "acção_06"
        case 4:
          return "acção_05"
        case 3:
          return "acção_04"
        case 2:
          return "acção_03"
        case 1:
          return "acção_02"
        default:
          return "acção_01"
      }
    case "Mobilização":
      switch (level) {
        case 3:
          return "mobilização_04"
        case 2:
          return "mobilização_03"
        case 1:
          return "mobilização_02"
        default:
          return "mobilização_01"
      }
    default:
      return "descoberta_01"
  }
}


class CategoryEvolution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iconUrl: ""
    }
  }

  componentDidMount() {
    // Render icon described as prop
    // Icons available: accao_[01-07], descoberta_[01-07], mobilização_[01-04]
    import(`../../assets/images/category-evolution--${selectedCategoryEvolution(this.props.type, this.props.level)}.svg`).then(image => {this.setState({iconUrl: image.default})})
  }

  themeClass(modifiers){
    return `c-category-evolution ${modifiers ? modifiers : ""}`
  }


  render() {
    return (
      <div className={this.themeClass(this.props.modifiers)}>
        <img src={this.state.iconUrl} alt={`${this.props.type}-icon`} className="c-category-evolution__icon" />
        <img src={Background} alt={`${this.props.type}-background`} className="c-category-evolution__background" />
      </div>
    )
  }
}

export default CategoryEvolution
