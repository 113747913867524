import React from 'react';

import { StudentDataContext } from '../providers/StudentDataProvider'
import Loader from '../components/game/loader'
import Problem from '../views/problem'
import { findProblemStatusInLevel, parseGameVersion } from '../utils/funcs'
import { PROBLEM_NAMES } from '../utils/copy'


class ProblemContainer extends React.Component {
  static contextType = StudentDataContext;

  render(){
    if(this.context.loading){
      return <Loader />
    }

    let game = parseGameVersion(this.context.activeClass.version);
    let level = this.props.location.state.level;
    let problem = this.props.location.state.problem;
    let onBackRedirectTo = this.props.location.state.onBackRedirectTo ? this.props.location.state.onBackRedirectTo : null;
    let challenges = this.context.challenges[level][problem];
    let problemStatus = findProblemStatusInLevel(this.context.student.gameState.levels[level].problems, problem);


    challenges.forEach( (challenge, idx) => {
      if(level !== 3){
        if(idx === 0){
          challenge.unlocked = true;
        } else {
          challenge.unlocked = challenges[idx-1].completed;
        }
      } else {
        challenge.unlocked = true;
      }

      challenge.completed = problemStatus.challenges[idx].completed;
      challenge.hidden = problemStatus.challenges[idx].hidden;
    });

    challenges =  challenges.filter(c => !c.hidden );

    return <Problem
              game={game}
              level={level}
              name={PROBLEM_NAMES[game][level][problem-1]}
              problem={problem}
              challenges={challenges}
              nChallengesCompleted={problemStatus.nChallengesCompleted}
              onBackRedirectTo={onBackRedirectTo}
            />
  }
}

export default ProblemContainer;
