import React from 'react';
import { Redirect } from 'react-router-dom'
import Input from '../components/game/appInput.js';
import Select from '../components/game/appSelect.js';
import LoginImage from '../assets/images/login-image.svg'
import AppFooter from '../components/game/appFooter.js';
import { parseGameVersion } from "../utils/funcs"
import { firestore, studentSignIn } from '../middlewares/firebase';
import Loader from '../components/game/loader.js';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      class: null,
      classId: null,
      confirmDisabled: true,
      studentsId: [...Array(40).keys()].map(i => i+1),
      studentNo: null
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    let classQuery = await firestore
      .collection("classes")
      .where("url", "==", this.props.match.params.slug)
      .get();
    
    // Sanity check to ensure we are getting a valid class  
    let validClasses = classQuery.docs.filter(doc => doc.id !== "null");
    
    if(classQuery.size === 0){
      this.setState({
        loading: false
      });
    } else {
      this.setState({
        loading: false,
        class: validClasses[0].data(),
        classId: validClasses[0].id
      });
    }
  }


  handleChange(event) {
    this.setState({
      studentNo: event.target.value,
      confirmDisabled : false
    });
  }

  handleSubmit(event) {
    this.setState({
      loading: true
    });
    
    studentSignIn(this.state.classId, this.state.studentNo);
    event.preventDefault();
  }

  showCufLogo(version) {
    if(parseGameVersion(version) === "cuf-saude") {
      return true
    } else {
      return false
    }
  }

  render() {
    if(this.state.loading) {
      return <Loader />
    }

    if(!this.state.class) {
      return <Redirect to="/" />
    }

    return (
      <div className="o-app__bg-dots">
        <div className="o-app__login">
          <div className="o-app__frame">
            <section className="o-view">
              <div className="o-app__container">
                <img
                  src={LoginImage}
                  alt="Login no SPOT Cidadania"
                  className="c-image-login"
                />
                <Input
                  id="school-name"
                  value={this.state.class.school}
                  readOnly={true}
                  placeholder="Nome da escola"
                  modifiers="u-mb u-mb-2x@sm"
                />
                <div className="o-app__container o-app__container--nowrap u-ph-0">
                  <Input
                    id="class"
                    value={this.state.class.name}
                    readOnly={true}
                    placeholder="Turma"
                    modifiers="c-input-group--xs u-mr u-mr-2x@sm"
                  />
                  <Input
                    id="student-label"
                    value="Qual é o teu nº de aluno?"
                    readOnly={true}
                    placeholder="Turma"
                    modifiers="u-mr u-mr-2x@sm"
                  />
                  <Select
                    id="student-number"
                    options={this.state.studentsId}
                    modifiers="c-input-group--xs"
                    value={this.state.studentNo}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="o-app__container u-mt-2x" data-flout="justify-end-- expand--">
                <button 
                  className="c-button u-mb-2x"
                  onClick={this.handleSubmit}
                  disabled={this.state.confirmDisabled}
                >
                  Next
                </button>
                <AppFooter isCuf={this.showCufLogo(this.state.class.version)}/>
              </div>
            </section>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
