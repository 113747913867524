import * as React from "react"

import Baloon from '../../assets/images/background-baloon-text.svg';
import BaloonChallenge0_1 from '../../assets/images/background-baloon-challenge-0-1.svg';
import BaloonChallenge1_1 from '../../assets/images/background-baloon-challenge-1-1.svg';
import BaloonChallenge1_2 from '../../assets/images/background-baloon-challenge-1-2.svg';
import BaloonChallenge2_1 from '../../assets/images/background-baloon-challenge-2-1.svg';
import BaloonChallenge2_2 from '../../assets/images/background-baloon-challenge-2-2.svg';
import BaloonChallenge2_3 from '../../assets/images/background-baloon-challenge-2-3.svg';
import BaloonChallenge3_1 from '../../assets/images/background-baloon-challenge-3-1.svg';
import BaloonChallenge3_2 from '../../assets/images/background-baloon-challenge-3-2.svg';
import BaloonChallenge3_3 from '../../assets/images/background-baloon-challenge-3-3.svg';
import BaloonChallenge3_4 from '../../assets/images/background-baloon-challenge-3-4.svg';

function themeClass(modifiers){
  return `c-text ${modifiers ? modifiers : ""}`
}

function selectBaloon(level, challenge){
  switch (level) {
    case 0:
      return BaloonChallenge0_1
    case 1:
      switch (challenge) {
        case 1:
          return BaloonChallenge1_1
        case 2:
          return BaloonChallenge1_2
        default:
          return Baloon
      }
    case 2:
      switch (challenge) {
        case 1:
          return BaloonChallenge2_1
        case 2:
          return BaloonChallenge2_2
        case 3:
          return BaloonChallenge2_3
        default:
          return Baloon
      }
    case 3:
      switch (challenge) {
        case 1:
          return BaloonChallenge3_1
        case 2:
          return BaloonChallenge3_2
        case 3:
          return BaloonChallenge3_3
        case 4:
          return BaloonChallenge3_4
        default:
          return Baloon
      }
    default:
      return Baloon
  }
}

function Text(props) {
  return (
    <p className={themeClass(props.modifiers)}>
      <img className="c-text__baloon" src={selectBaloon(props.level, props.challenge)} alt=""/>
      {props.content}
    </p>
  )
}

export default Text