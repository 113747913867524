import './App.scss'
import  PageProvider from './providers/PageProvider.js'
import  AuthProvider from './providers/AuthProvider.js'
import ApplicationRouter from './routes/ApplicationRouter'

function App() {
  return (
    <AuthProvider>
      <PageProvider>
        <div className="o-app">
          <ApplicationRouter />
        </div>
      </PageProvider>
    </AuthProvider>
  );
}

export default App;
