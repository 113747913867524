import * as React from "react"

class BdPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      background: "",
      avatar: "",
      ballons: ""
    }
  }
  componentDidMount() {
    if (this.props.panel === 1) {
        import(`../../assets/images/${this.props.game}/avatars/${this.props.avatarLevel}/avatar-${this.props.avatarTheme}.svg`).then(image => {this.setState({avatar: image.default})})
    } else {
        import(`../../assets/images/${this.props.game}/chapter-4/background/panel-${this.props.panel}.svg`).then(image => {this.setState({background: image.default})})
        import(`../../assets/images/${this.props.game}/chapter-4/avatar/avatar-${this.props.avatarTheme}/panel-${this.props.panel}.svg`).then(image => {this.setState({avatar: image.default})})

      if (this.props.completed){
        import(`../../assets/images/${this.props.game}/chapter-4/ballons/panel-${this.props.panel}.svg`).then(image => {this.setState({ballons: image.default})})

      } else {
        import(`../../assets/images/${this.props.game}/chapter-4/ballons/panel-${this.props.panel}-uncompleted.svg`).then(image => {this.setState({ballons: image.default})})
      }
    }
  }
  render() {
    return(
      <div className="o-bd__panel">
        {this.props.panel > 1 && <img src={this.state.background} alt="fundo" className="o-bd__panel__background"/>}
        <img src={this.state.avatar} alt="avatar" className={`o-bd__panel${this.props.panel > 1 ? "__overlay" : ""}`}/>
        {this.props.panel > 1 && <img src={this.state.ballons} alt="text" className="o-bd__panel__overlay"/>}
      </div>
    )
  }
}

export default BdPanel
