import * as React from "react"

function themeClass(modifiers){
  return `c-input-group c-input-group--select ${modifiers ? modifiers : ""}`
}

function selectOptions(options) {
  // Render the list of options provided as prop.options array
  var optionsEl = options.map(function(value) {
    return (
    <option value={value} key={value}>{value}</option>
  )
  })
  return optionsEl
}

function AppSelect(props) {
  return (
    <div className={themeClass(props.modifiers)}>
      <label className="c-input-label" htmlFor={props.id}>{props.label}</label>
      <select className="c-input c-input--select" name={props.id} id={props.id} onChange={props.onChange} defaultValue="-">
        <option disabled value="-">-</option>
        {selectOptions(props.options)}
      </select>
    </div>
  )
}

export default AppSelect