import React, { Component, createContext } from "react";
import { fire, auth, firestore, getClassesForCurrentTeacher, isCurrentTeacherAdmin } from "../middlewares/firebase";

export const TeacherDataContext = createContext({ teacher: null });
class TeacherDataProvider extends Component {
  constructor(props) {
    super(props);

    this.classLogin = this.classLogin.bind(this);
    this.classLogout = this.classLogout.bind(this);

    this.state = {
      teacher: null,
      teacherClasses: null,
      activeClass: null,
      teacherId: props.teacherId,
      classLogin: this.classLogin,
      classLogout: this.classLogout,
      admin: false,
      loading: true
    }
  }

  dataListener = null;

  classLogin(classId) {
    this.setState({
      loading: true
    });

    window.localStorage.setItem('activeClassId', classId);

    this.dataListener = firestore.collection("classes").doc(classId)
      .onSnapshot((doc) => {
        this.setState({
          loading: false,
          activeClass: doc.data(),
          classId: doc.id
        });
      });
  }

  classLogout() {
    if (this.dataListener) {
      this.dataListener();
    }

    window.localStorage.removeItem('activeClassId');

    this.setState({
      classId: null,
      activeClass: null
    });
  }

  componentDidMount = async () => {
    // On first mount, we need to setup the first fetch and 
    // refresh actions for getting teacher/class data
    let teacherClasses = (await getClassesForCurrentTeacher());

    this.setState({
      loading: false,
      teacher: auth.currentUser,
      teacherClasses,
      admin: isCurrentTeacherAdmin()
    });

    let activeClassId = window.localStorage.getItem('activeClassId');
    if (activeClassId) {
      this.classLogin(activeClassId);
    }
  }

  componentWillUnmount = () => {
    if (this.dataListener) {
      this.dataListener();
    }

    window.localStorage.removeItem('activeClassId');
  }

  render() {
    return (
      <TeacherDataContext.Provider value={this.state}>
        {this.props.children}
      </TeacherDataContext.Provider>
    );
  }
}

export default TeacherDataProvider;