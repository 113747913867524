import * as React from "react"
import Tabs from "./tabs.js"
import ProfileTab from "./profileTab.js"

function TabNavigation(props) {
  return (
    <div className="o-navigation">
      <Tabs />
      <ProfileTab
        justCompleted={props.justCompleted}
      />
    </div>
  )
}


export default TabNavigation
