import * as React from "react"

function progress(page){
  var opacity = page.completed ? 1 : page.nChallengesCompleted / page.challenges.length;

  return {opacity: opacity};
}


class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      background: "",
      avatar: "",
      ballons: "",
    }
  }
  componentDidMount() {
    if (this.props.completed){
      if (this.props.level < 3){
        import(`../../assets/images/${this.props.game}/collection/ending-${this.props.level}-${this.props.problem.name}.svg`).then(image => {this.setState({background: image.default})})
      } else {
        import(`../../assets/images/${this.props.game}/collection/ending-${this.props.level}-${this.props.problem.name}-${this.props.avatarTheme}.svg`).then(image => {this.setState({background: image.default})})
      }
    } else {
      if (this.props.level < 3){
        import(`../../assets/images/${this.props.game}/chapter-${this.props.level}/page-${this.props.problem.name}/background/done/panel-2.svg`).then(image => {this.setState({background: image.default})})
        import(`../../assets/images/${this.props.game}/chapter-${this.props.level}/page-${this.props.problem.name}/avatar/avatar-${this.props.avatarTheme}/done/panel-2.svg`).then(image => {this.setState({avatar: image.default})})
        import(`../../assets/images/${this.props.game}/chapter-${this.props.level}/page-${this.props.problem.name}/ballons/done/panel-2.svg`).then(image => {this.setState({ballons: image.default})})
      }
    }
  }
  render() {
    return(
      <div className="c-item-collection__strip-container" style={progress(this.props.problem)}>
        <img src={this.state.background} alt="fundo" className="o-bd__panel__background"/>
        { !this.props.completed &&
          <React.Fragment>
            <img src={this.state.avatar} alt="avatar" className="o-bd__panel__overlay"/>
            <img src={this.state.ballons} alt="text" className="o-bd__panel__overlay"/>
          </React.Fragment>
        }
      </div>
    )
  }
}

export default Item

