export const GAME_VERSIONS = {
  SAUDE: "cuf-saude",
  INTERCULTURALIDADE_3C: "interculturalidade-3c",
  DESENVOLVIMENTO_3C: "desenvolvimento-sustentavel-3c",
  INTERCULTURALIDADE_2C: "interculturalidade-2c",
  DESENVOLVIMENTO_2C: "desenvolvimento-sustentavel-2c",
  IGUALDADE_2C: "igualdade-genero-2c",
  EDUCACAO_2C: "educacao-ambiental-2c"
}
export const STUDENT_EMAIL_DOMAIN = "students.spotgames";
export const USERTYPES = {
  student: "student",
  teacher: "teacher"
};

export const CLASS_STATUS = {
  OPEN: "open",
  PLAYING: "playing",
  CLOSED: "closed"
};

export const ACTION_PANEL = {
  0: [
    5,
    3,
  ],
  1: [
    2,
    2,
    2
  ],
  2: [
    2,
    2
  ],
  3: [
    3
  ]
};