import React, { useContext } from 'react'
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

import SelectClass from '../views/selectClass'
import TeacherDashboard from '../views/teacherDashboard.js'
import StudentAnswers from '../views/studentAnswers'
import MissionsOverview from '../views/missionsOverview.js'

import { TeacherDataContext } from "../providers/TeacherDataProvider"

import Loader from '../components/game/loader'

const TeacherRouter = (props) => {
  const { loading, activeClass } = useContext(TeacherDataContext);

  if (loading) {
    // In this particular case, we're still loading teacher data
    return <Loader />
  }

  if (!activeClass) {
    return <SelectClass />
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/'>
          <Redirect to='/pagina-professor' />
        </Route>
        <Route path='/pagina-professor'
          component={TeacherDashboard}>
        </Route>
        <Route path='/respostas/:studentId'
          component={StudentAnswers}>
        </Route>
        <Route path='/missoes'
          component={MissionsOverview}>
        </Route>
        <Route>
          <Redirect to='/pagina-professor' />
        </Route>
      </Switch>
    </BrowserRouter>
  )
};

export default TeacherRouter;
