import * as React from "react"
import { PageContext } from "../../providers/PageProvider.js"

class PageNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previous: {
        level: 0,
        problem: 1,
        disabled: true,
        hidden: true
      },
      next: {
        level: 0,
        problem: 2,
        disabled: true,
        hidden: false
      }
    }
  }

  componentDidMount() {
    // set previous level and problem
    if (this.props.activeProblem === 1){
      if (this.props.activeLevel === 0){
        this.setState({previous:{level: 0, problem: 1, disabled: true, hidden: true}})
      } else {
        this.setState({previous:{level: this.props.activeLevel-1, problem: this.props.levels[this.props.activeLevel-1].problems.length, disabled: false, hidden: false}})
      }
    } else {
      this.setState({previous:{level: this.props.activeLevel, problem: this.props.activeProblem-1, disabled: false, hidden: false }})
    }
    // set next level and problem
    if (this.props.activeProblem === this.props.levels[this.props.activeLevel].problems.length){
      if (this.props.activeLevel === 3){
        this.setState({next:{level: 3, problem: 1, disabled: true, hidden: true}})
      } else if (this.props.levels[this.props.activeLevel+1].unlocked){
        this.setState({next:{level: this.props.activeLevel+1, problem: 1, disabled: false, hidden: false}})
      } else {
        this.setState({next:{level: this.props.activeLevel, problem: this.props.activeProblem, disabled: true, hidden: false}})
      }
    } else {
      this.setState({next:{level: this.props.activeLevel, problem: this.props.activeProblem+1, disabled: false, hidden: false}})
    }
  }
 
  render() {
    return (
      <PageContext.Consumer>
        {({setPage}) => (
          <div className="u-pb" data-flout="justify-between-- expand--">
            <div>
              <button className="c-button"
                onClick={(e) => setPage(this.state.previous.level,this.state.previous.problem)}
                disabled={this.state.previous.disabled}
                hidden={this.state.previous.hidden}
              >
                Voltar
              </button>
            </div>
            <div>
              <button className="c-button"
                onClick={(e) => setPage(this.state.next.level,this.state.next.problem)}
                disabled={this.state.next.disabled}
                hidden={this.state.next.hidden}
                >
                Avançar
              </button>
            </div>
          </div>
         )}
      </PageContext.Consumer>
    )
  }
}

export default PageNavigation

