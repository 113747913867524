import React, { useContext } from 'react'
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import { CLASS_STATUS } from '../utils/constants'
import AvatarSelection from '../views/avatarSelection.js'
import ProblemContainer from '../views/problemContainer'
import Level from '../views/level.js'
import ProfileView from '../views/profileView'
import LastChapter from '../views/lastChapter'
import Logout from '../views/logout'

import { StudentDataContext } from "../providers/StudentDataProvider"

import Loader from '../components/game/loader'

const StudentRouter = (props) => {
  const { student, loading, activeClass }  = useContext(StudentDataContext);

  if( loading ) {
    // In this particular case, we're still loading student data
    return <Loader/>
  }

  if( !student ) {
    // In this case we have logged in, but have no student object
    // meaning we have to complete student registration
    return <AvatarSelection />
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/'>
          <Redirect to='/nivel' />
        </Route>
        <Route path='/problema'
          component={ProblemContainer}
        />
        <Route path='/perfil'>
          <ProfileView />
        </Route>
        <Route path='/fim'>
          {activeClass.status === CLASS_STATUS.CLOSED ? <LastChapter /> : <Redirect to='/perfil' />}
        </Route>
        <Route path='/nivel'
          component={Level}
        />
        <Route path='/logout'>
          <Logout />
        </Route>
        <Route>
          <Redirect to='/nivel' />
        </Route>
      </Switch>
    </BrowserRouter>
  )
};

export default StudentRouter;
